import React, { useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import "animate.css/animate.min.css";

const industriesHealthcare =
  "https://images.reactbytes.com/react-bytes/industries-focus-healthcare.svg";
const industrieseCommerce =
  "https://images.reactbytes.com/react-bytes/industries-focus-ecommerce.svg";
const industriesFinance =
  "https://images.reactbytes.com/react-bytes/industries-focus-finance.svg";
const industriesRealEstate =
  "https://images.reactbytes.com/react-bytes/industries-focus-real-estate.svg";
const industriesEducation =
  "https://images.reactbytes.com/react-bytes/industries-focus-education.svg";
const industriesManufacturing =
  "https://images.reactbytes.com/react-bytes/industries-focus-manufacturing.svg";
const industriesEntertainment =
  "https://images.reactbytes.com/react-bytes/industries-focus-entertainment.svg";
const industriesTransportation =
  "https://images.reactbytes.com/react-bytes/industries-focus-transportation.svg";
const industriesLegal =
  "https://images.reactbytes.com/react-bytes/industries-focus-legal.svg";
const industriesHospitality =
  "https://images.reactbytes.com/react-bytes/industries-focus-hospitality.svg";

const IndustriesFocusReact = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);

  const industriesFocus = [
    {
      industriesImage: industriesHealthcare,
      industriesName: "Healthcare",
    },
    {
      industriesImage: industrieseCommerce,
      industriesName: "eCommerce",
    },
    {
      industriesImage: industriesFinance,
      industriesName: "Finance",
    },
    {
      industriesImage: industriesRealEstate,
      industriesName: "Real-Estate",
    },
    {
      industriesImage: industriesEducation,
      industriesName: "Education",
    },
    {
      industriesImage: industriesManufacturing,
      industriesName: "Manufacturing",
    },
    {
      industriesImage: industriesEntertainment,
      industriesName: "Entertainment",
    },
    {
      industriesImage: industriesTransportation,
      industriesName: "Transportation",
    },
    {
      industriesImage: industriesLegal,
      industriesName: "Legal",
    },
    {
      industriesImage: industriesHospitality,
      industriesName: "Hospitality",
    },
  ];

  return (
    <>
      <div className="py-[40px] md:py-[60px] lg:py-[100px] bg-[#F8F9FA]">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row items-center md:gap-[60px] xl:gap-[100px] 2xl:gap-[120px]">
            <div className="industries-focus-react-info">
              <div
                className="section-title wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Industries In Focus
              </div>
              <div
                className="section-sub-title md:mb-[30px] lg:mb-[60px] 2xl:!text-[20px] wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                Whether you're in healthcare, retail, or any field in between,
                we deliver solutions that cater to the specific challenges and
                demands of your business.
              </div>
              <Link
                to={"/portfolio"}
                className="btn-black whitespace-nowrap !hidden md:!inline-flex wow animate__animated animate__fadeInUp"
                data-wow-delay="0.6s"
              >
                See More Portfolio
              </Link>
            </div>
            <ul className="industries-focus-react-icon">
              {industriesFocus.map((data, i) => (
                <li key={i}>
                  <img
                    src={data.industriesImage}
                    alt=""
                    width={88}
                    height={88}
                  />
                  <span>{data.industriesName}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndustriesFocusReact;
