import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../style/scroll.css";
import "../../style/style-new.css";
import "../../style/media.css";

const Logo = "https://images.reactbytes.com/react-bytes/reactbytes-logo.svg";
const LogoBlack =
  "https://images.reactbytes.com/react-bytes/reactbytes-logo-black.svg";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [toggle, setToggle] = useState(false);

  const handleClassRemove = () => {
    document.body.className = "";
  };

  return (
    <>
      <div className={`header ${scrolled ? "headerFixed" : ""}`}>
        <div className="container mx-auto px-4">
          <div className="navigation">
            <Link to={process.env.PUBLIC_URL + "/"}>
              <img src={Logo} alt="" className="logo-white" />
              <img src={LogoBlack} alt="" className="hidden" />
            </Link>
            <nav className={`navMenu ${toggle ? `toggleMenu` : ""}`}>
              <button
                className="navbar-toggler hidden"
                type="button"
                onClick={() => {
                  setToggle((toggle) => !toggle);
                  if (!toggle) {
                    document.body.className = "overflow-hidden";
                  } else {
                    document.body.className = "";
                  }
                }}
              >
                <span className="one"></span>
                <span className="two"></span>
                <span className="three"></span>
              </button>
              <ul>
                <li>
                  <Link className="submenu-link">
                    Our Services{" "}
                    <svg
                      className="menu-dropdown-arrow"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 9L12 15L18 9"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                  <ul className="dropdDownSubmenu">
                    <li>
                      <Link
                        to={process.env.PUBLIC_URL + "/reactjs-development"}
                        onClick={handleClassRemove}
                      >
                        React.js Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          process.env.PUBLIC_URL + "/react-native-development"
                        }
                        onClick={handleClassRemove}
                      >
                        React Native Development
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to={process.env.PUBLIC_URL + "/portfolio"}
                    onClick={handleClassRemove}
                  >
                    Our Work
                  </Link>
                </li>
                <li>
                  <Link className="submenu-link">
                    Hire Team{" "}
                    <svg
                      className="menu-dropdown-arrow"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 9L12 15L18 9"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                  <ul className="dropdDownSubmenu">
                    <li>
                      <Link
                        to={process.env.PUBLIC_URL + "/hire-reactjs-developer"}
                        onClick={handleClassRemove}
                      >
                        Hire React JS Developer
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          "/hire-react-native-developer"
                        }
                        onClick={handleClassRemove}
                      >
                        Hire React Native Developer
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to={process.env.PUBLIC_URL + "/testimonials"}
                    onClick={handleClassRemove}
                  >
                    Testimonials
                  </Link>
                </li>
                <li className="!border-b-0">
                  <Link
                    to={process.env.PUBLIC_URL + "/blog"}
                    onClick={handleClassRemove}
                  >
                    Insight
                  </Link>
                </li>
                <li className="!border-b-0">
                  <Link
                    to={process.env.PUBLIC_URL + "/contact-us"}
                    onClick={handleClassRemove}
                    className="default-btn"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
