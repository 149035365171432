import React, { useEffect, useLayoutEffect } from "react";
import "isomorphic-fetch";
import { useQuery, gql } from "@apollo/client";
import Spinner from "../../Common/Spinner";
import { BlogCell } from "./BlogCell";
import { Waypoint } from "react-waypoint";
import Footer from "../include/Footer";
import Header from "../include/Header";
import ScrollToTop from "../Component/ScrollTotop";
import "animate.css/animate.min.css";
import * as Constant from "../../utils/constant";
import MetaTags from "../Component/MetaTags ";

const Blog = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);

  const GET_DATA = gql`
    query ($after: String) {
      posts(first: 9, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            postId
            title
            excerpt
            date
            slug
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `;

  const { data, error, loading, fetchMore } = useQuery(
    GET_DATA,

    {
      variables: { after: null },
    }
  );

  console.log("data", data, "error", error, "loading", loading);
  console.log("allblogs", data);

  //   if (loading){
  // return( <Spinner/>)

  //   }

  return (
    <>
      {loading === true ? (
        <Spinner />
      ) : (
        <div>
          <div className="smallBanner bg-[#002A42]">
            <img
              src="https://d12ydcmiv69ory.cloudfront.net/reactbytes/blog-bg.jpg"
              alt="Banner"
              className="smallBannerBg"
            />
            <Header />
            <MetaTags
              title="Latest Blogs on ReactJS, React Native, React Mobile App and React Native Web"
              description="Here you can find the latest news and informative blogs about ReactJs and React Native that will be useful for both beginners, react native developer and companies."
              keywords="ReactJS, React Native, React Mobile App and React Native Web, React Native Developer"
              ogImage={Constant.REACT_BYTES_BLOGS_BG}
              ogURL={Constant.WEBSITE_URL + "/blog"}
              author={Constant.WEBSITE_AUTHOR_URL}
              facebookURL={Constant.FACEBOOK_URL}
              twitterSite={Constant.TWITTER_PAGE_CREATOR}
              twitterCreator={Constant.TWITTER_PAGE_CREATOR}
            ></MetaTags>
            <div className="container mx-auto px-4">
              <div
                className="inner-page-title text-white wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Blog
              </div>
              <p
                className="text-white text-center text-[18px] md:text-[20px] lg:text-[22px] mt-3 lg:mt-4 leading-tight wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                Here is what we have to say, check out latest technology news &
                Blogs.
              </p>
            </div>
          </div>
          <div className="py-[40px] md:py-[60px] lg:py-[80px] blog-page-list">
            <div className="container mx-auto px-4">
              <div className="flex flex-wrap -mx-[16px] gap-y-[24px] lg:gap-y-[40px]">
                {data.posts.edges.map((item, index) => (
                  <div className="insight-width" key={index}>
                    <div className="insight-box">
                      <BlogCell
                        id={item.node.id}
                        key={item.node.title}
                        image={
                          item.node.featuredImage
                            ? item.node.featuredImage.node.sourceUrl
                            : ""
                        }
                        title={item.node.title}
                        description={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.node.excerpt,
                            }}
                          ></div>
                        }
                        date={item.node.date}
                        slug={item.node.slug}
                      />
                    </div>
                  </div>
                ))}

                {data.posts.pageInfo.hasNextPage === true ? (
                  <div className="blogspinner my-4">
                    <div className="balls">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {data.posts.pageInfo.hasNextPage === true ? (
                  <Waypoint
                    onEnter={() => {
                      const { endCursor } = data.posts.pageInfo;
                      console.log("endCursor", endCursor);

                      fetchMore({
                        variables: { after: endCursor },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                          console.log("previous added", previousResult);
                          if (!fetchMoreResult) {
                            return previousResult;
                          }

                          const previousEdges = previousResult.posts.edges;
                          console.log("previousEdges", previousEdges);
                          const fetchMoreEdges = fetchMoreResult.posts.edges;
                          console.log("fetchMoreEdges", fetchMoreEdges);

                          var previousBlogs = new Set(
                            previousEdges.map((id) => id.node.postId)
                          );
                          console.log("previous", previousBlogs);

                          fetchMoreResult.posts.edges = [
                            ...previousEdges,
                            ...fetchMoreEdges.filter(
                              (d) => !previousBlogs.has(d.node.postId)
                            ),
                          ];
                          return { ...fetchMoreResult };
                        },
                      });
                    }}
                  ></Waypoint>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}
    </>
  );
};

export default Blog;
