import React from "react";
import { Link } from "react-router-dom";
import Footer from "./include/Footer";
import Header from "./include/Header";
import ScrollToTop from "./Component/ScrollTotop";
import MetaTags from "./Component/MetaTags ";
import config from "../utils/config";
import * as Constant from "../utils/constant";

const NotFoundPage = () => {
  return (
    <>
      <div className="headerFixed">
        <Header />
        <MetaTags
          title="ReactJS Development Company, React Native Development Services for Web and Mobile"
          description="We are ReactJS Development Company based in India, offers React Native Development and ReactJS Development Services for Websites and Mobile Apps. Get a Free Quote and Hire ReactJS Expert from ReactBytes."
          keywords="ReactJS Development Company, React Native Development, ReactJS Development Services, ReactJS for Web Development"
          ogImage={config.LIVE_IMAGE_BASE_URL + "landing-page-og-img.jpg"}
          ogURL={Constant.WEBSITE_URL + "/404"}
          author={Constant.WEBSITE_AUTHOR_URL}
          facebookURL={Constant.FACEBOOK_URL}
          twitterSite={Constant.TWITTER_PAGE_CREATOR}
          twitterCreator={Constant.TWITTER_PAGE_CREATOR}
        ></MetaTags>
      </div>
      <div className="container mx-auto px-4">
        <div className="page-404">
          <h3
            className="wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            404
          </h3>
          <div className="four-zero-four-bg"></div>
          <h4
            className="wow animate__animated animate__fadeInUp"
            data-wow-delay="0.5s"
          >
            Opps! Page not found
          </h4>
          <Link className="btn-black" to="/">
            Go to Home
          </Link>
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default NotFoundPage;
