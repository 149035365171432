import React, { useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import "animate.css/animate.min.css";

const industries1 =
  "https://images.reactbytes.com/react-bytes/industries-healthcare.svg";
const industries2 =
  "https://images.reactbytes.com/react-bytes/industries-ecommerce.svg";
const industries3 =
  "https://images.reactbytes.com/react-bytes/industries-finance.svg";
const industries4 =
  "https://images.reactbytes.com/react-bytes/industries-real-estate.svg";
const industries5 =
  "https://images.reactbytes.com/react-bytes/industries-education.svg";
const industries6 =
  "https://images.reactbytes.com/react-bytes/industries-manufacturing.svg";
const industries7 =
  "https://images.reactbytes.com/react-bytes/industries-entertainment.svg";
const industries8 =
  "https://images.reactbytes.com/react-bytes/industries-transportation.svg";
const industries9 =
  "https://images.reactbytes.com/react-bytes/industries-legal.svg";
const industries10 =
  "https://images.reactbytes.com/react-bytes/industries-restaurant.svg";

const IndustriesFocus = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);

  const industriesFocus = [
    {
      industriesImage: industries1,
      industriesName: "Healthcare",
    },
    {
      industriesImage: industries2,
      industriesName: "eCommerce",
    },
    {
      industriesImage: industries3,
      industriesName: "Finance",
    },
    {
      industriesImage: industries4,
      industriesName: "Real-Estate",
    },
    {
      industriesImage: industries5,
      industriesName: "Education",
    },
    {
      industriesImage: industries6,
      industriesName: "Manufacturing",
    },
    {
      industriesImage: industries7,
      industriesName: "Entertainment",
    },
    {
      industriesImage: industries8,
      industriesName: "Transportation",
    },
    {
      industriesImage: industries9,
      industriesName: "Legal",
    },
    {
      industriesImage: industries10,
      industriesName: "Restaurant",
    },
  ];

  return (
    <>
      <div className="py-[40px] md:py-[60px] lg:py-[100px] bg-[#F8F9FA]">
        <div className="container mx-auto px-4">
          <div className="flex items-end justify-between mb-[40px] lg:mb-[60px] gap-5 text-center md:text-left">
            <div>
              <div
                className="section-title wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Industries In Focus
              </div>
              <div
                className="section-sub-title wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                Whether you're in healthcare, retail, or any field in between,
                we deliver solutions that cater to the specific
                <br className="hidden xl:block" /> challenges and demands of
                your business.
              </div>
            </div>
            <Link
              to={"/portfolio"}
              className="btn-black whitespace-nowrap !hidden md:!inline-flex wow animate__animated animate__fadeInUp"
              data-wow-delay="0.4s"
            >
              See More Portfolio
            </Link>
          </div>
          <ul className="industries-focus">
            {industriesFocus.map((data, i) => (
              <li key={i}>
                <img src={data.industriesImage} alt="" className="mx-auto" />
                <span>{data.industriesName}</span>
              </li>
            ))}
          </ul>
          <div className="text-center inline-flex justify-center md:hidden w-full mt-5">
            <Link to={"/portfolio"} className="btn-black whitespace-nowrap">
              See More Portfolio
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndustriesFocus;
