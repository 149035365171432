import { React, useEffect, useLayoutEffect } from "react";
import Header from "../include/Header";
import Footer from "../include/Footer";
import ScrollToTop from "../Component/ScrollTotop";
import "animate.css/animate.min.css";
import config from "../../utils/config";
import * as Constant from "../../utils/constant";

import "../../Pages/Casestudy/css/casestudy-style.css";
import "../../Pages/Casestudy/css/casestudy-media.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OurProcess from "./Component/OurProcess";
import MetaTags from "../Component/MetaTags ";

const BannerImage =
  "https://images.reactbytes.com/react-bytes/gift-center-banner.png";
const giftCenterKey1 =
  "https://images.reactbytes.com/react-bytes/gift-center-key-1.png";
const giftCenterKey2 =
  "https://images.reactbytes.com/react-bytes/gift-center-key-2.png";
const technologyIcon1 =
  "https://images.reactbytes.com/react-bytes/reactjs-icon.svg";
const technologyIcon2 =
  "https://images.reactbytes.com/react-bytes/JavaScript-logo.png";
const technologyIcon3 =
  "https://images.reactbytes.com/react-bytes/php-logo.svg";
const technologyIcon4 =
  "https://images.reactbytes.com/react-bytes/mysql-logo.svg";
const technologyIcon5 =
  "https://images.reactbytes.com/react-bytes/aws-icon.png";
const GiftCenter = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);
  var technologyStack = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <div className="innerPageHeader casesudyBanner bg-[#002855]">
        <Header />
        <MetaTags
          title="Gifts Center App Case Study | Simplifying Gift Shopping"
          description="See how Gifts Center's app streamlined gift shopping. Discover the innovative solutions that made finding the perfect gift easier and more enjoyable."
          keywords="Gifts Center app, gift shopping, mobile application, case study, app success, streamlined shopping, innovative solutions"
          ogImage={config.LIVE_IMAGE_BASE_URL + "gift-center-work-og-img.jpg"}
          ogURL={Constant.WEBSITE_URL + "/casestudy/gift-center"}
          author={Constant.WEBSITE_AUTHOR_URL}
          facebookURL={Constant.FACEBOOK_URL}
          twitterSite={Constant.TWITTER_PAGE_CREATOR}
          twitterCreator={Constant.TWITTER_PAGE_CREATOR}
        ></MetaTags>
        <div className="container mx-auto px-4">
          <div className="detailPageBanner">
            <div className="detailBannerinfo">
              <h3 className="inner-page-title">
                E-Commerce App with streamlined Ordering and Delivery Services
              </h3>
              <p>
                Online ecommerce store that offers a wide range of fragrances,
                cosmetics, watches, and jewelry
              </p>
            </div>
            <div className="detailBannerPic !items-end">
              <img src={BannerImage} alt="Banner" className="max-w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center md:text-left wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Project Overview
          </div>
          <div
            className="section-sub-title !font-normal wow animate__animated animate__fadeInUp"
            data-wow-delay="0.4s"
          >
            The Gifts Center is an eCommerce web and mobile app has
            revolutionized the way individuals shop for gifts. This case study
            explores the features, benefits, and impact of Gifts Center in
            simplifying the gift shopping experience for users. Developed by a
            team of innovative developers, the application offers a
            comprehensive platform for discovering, selecting, and purchasing
            gifts for various occasions.
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] bg-[#E7E8F6]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Key Features
          </div>
          <div className="key-feature-row">
            <div className="key-feature-pic">
              <img src={giftCenterKey1} alt="" />
              <div className="key-image-bg"></div>
            </div>
            <div className="key-feature-info">
              <ul>
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <strong>Extensive Gift Collection: Gifts Center</strong>{" "}
                  offers a vast collection of gifts for all occasions, including
                  birthdays, anniversaries, weddings, graduations, and holidays.
                  Users can explore categories such as Fragrances, Watches,
                  Makeup, Accessories, Eye Wear, Skin Care, Writing Instrum,
                  Leather Goods, Glass bottles, and more.
                </li>
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <strong>Personalized Recommendations:</strong> Leveraging
                  advanced algorithms, <strong>Gifts Center</strong> provides
                  personalized gift recommendations based on user preferences,
                  previous purchases, and browsing history. This feature helps
                  users discover unique and suitable gifts, saving them time and
                  effort. There are sharing options that allow users to share
                  their lists via social media platforms or email.
                </li>
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <strong>Wishlist Creation:</strong> Users can create
                  personalized wishlists within the application, making it
                  easier for friends and family to select gifts for them.
                </li>
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <strong>Easy Search and Filtering: Gifts Center</strong>{" "}
                  enables users to search for specific gifts using keywords,
                  categories, or price ranges. Advanced filtering options allow
                  users to search by their voices.
                </li>
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.7s"
                >
                  <strong>Secure Payment Gateway:</strong> The application
                  integrates with secure payment gateways, ensuring the safety
                  of users and we give them different payment methods so the
                  user can select the payment method with which he/she is
                  comfortable.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] bg-[#FAFAFA]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            User Benefits
          </div>
          <div className="key-feature-row key-feature-gray">
            <div className="key-feature-info">
              <ul>
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <strong>Time and Effort Savings:</strong> Users don't need to
                  go here and there for finding the gift which users need. All
                  the gifts are provided by the <strong>Gifts Center</strong>{" "}
                  application. The intuitive interface and personalized
                  recommendations reduce the time spent searching for the
                  perfect gift.
                </li>
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <strong>Enhanced Gift Selection:</strong> With an extensive
                  collection of gifts and personalized recommendations, users
                  have access to a wide range of options. They can easily find
                  unique and thoughtful gifts that cater to the recipient's
                  preferences and occasion.
                </li>
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <strong>Simplified Wishlist Sharing:</strong> Gifts Center
                  simplifies the process of creating and sharing wishlists,
                  making it convenient for users to communicate their gift
                  preferences to friends and family. This feature helps users to
                  communicate with family and friends easily and can get reviews
                  of the member for buying.
                </li>
              </ul>
            </div>
            <div className="key-feature-pic">
              <img src={giftCenterKey2} alt="" />
              <div className="key-image-bg"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Our Process
          </div>
          <OurProcess />
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] bg-[#FAFAFA]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Technologies We Used
          </div>
          <Slider {...technologyStack} className="technology-stack-slider">
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon1} alt="" />
              </div>
              <h4>React Native</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon2} alt="" />
              </div>
              <h4>JavaScript</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon3} alt="" />
              </div>
              <h4>PHP</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon4} alt="" />
              </div>
              <h4>MySQL</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon5} alt="" />
              </div>
              <h4>AWS</h4>
            </div>
          </Slider>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] text-center">
        <div className="container mx-auto px-4">
          <div
            className="section-title wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Conclusion
          </div>
          <div
            className="section-sub-title xl:px-[14%] 2xl:px-[17.5%] wow animate__animated animate__fadeInUp"
            data-wow-delay="0.4s"
          >
            <strong>Gifts Center</strong> has emerged as a leading mobile
            application for gift shopping, offering users a convenient,
            time-saving, and personalized experience. With its extensive gift
            collection, personalized recommendations, and secure payment
            options, the application has revolutionized the way individuals
            discover and purchase gifts for various occasions. As technology
            continues to advance, <strong>Gifts Center</strong> remains
            committed to enhancing the gift shopping experience and delighting
            its users.
          </div>
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default GiftCenter;
