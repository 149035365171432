import { React, useEffect, useLayoutEffect } from "react";
import Header from "../include/Header";
import Footer from "../include/Footer";
import ScrollToTop from "../Component/ScrollTotop";
import "animate.css/animate.min.css";
import config from "../../utils/config";
import * as Constant from "../../utils/constant";
import "../../Pages/Casestudy/css/casestudy-style.css";
import "../../Pages/Casestudy/css/casestudy-media.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OurProcess from "./Component/OurProcess";
import MetaTags from "../Component/MetaTags ";

const casestudyBannerBg =
  "https://images.reactbytes.com/react-bytes/headsup-banner-bg.jpg";
const BannerImage =
  "https://images.reactbytes.com/react-bytes/headsup-banner.png";
const challengePicture =
  "https://images.reactbytes.com/react-bytes/headsup-challenges.png";
const headsupKey1 =
  "https://images.reactbytes.com/react-bytes/headsup-key-1.png";
const headsupKey2 =
  "https://images.reactbytes.com/react-bytes/headsup-key-2.png";
const headsupKey3 =
  "https://images.reactbytes.com/react-bytes/headsup-key-3.png";
const headsupKey4 =
  "https://images.reactbytes.com/react-bytes/headsup-key-4.png";
const technologyIcon1 =
  "https://images.reactbytes.com/react-bytes/reactjs-icon.svg";
const technologyIcon2 =
  "https://images.reactbytes.com/react-bytes/reactjs-icon.svg";
const technologyIcon3 =
  "https://images.reactbytes.com/react-bytes/ai-ml-icon.svg";
const technologyIcon4 =
  "https://images.reactbytes.com/react-bytes/aws-icon.png";
const technologyIcon5 =
  "https://images.reactbytes.com/react-bytes/jmeter-icon.svg";
const Headsup = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);
  var technologyStack = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <div className="innerPageHeader casesudyBanner">
        <img
          src={casestudyBannerBg}
          alt="Banner"
          className="casestudyBannerBg"
        />
        <Header />
        <MetaTags
          title="HeadsUp - Health & Fitness Tracking App | App Success Case Study"
          description="Read the case study on Heads UP app's success in health and fitness tracking. Discover the challenges and innovative solutions."
          keywords="Health and Fitness Tracking App, Fitness Tracking App Development, Case Study, Health Tracking App Development"
          ogImage={config.LIVE_IMAGE_BASE_URL + "heads-up-work-og-img.jpg"}
          ogURL={Constant.WEBSITE_URL + "/casestudy/headsup"}
          author={Constant.WEBSITE_AUTHOR_URL}
          facebookURL={Constant.FACEBOOK_URL}
          twitterSite={Constant.TWITTER_PAGE_CREATOR}
          twitterCreator={Constant.TWITTER_PAGE_CREATOR}
        ></MetaTags>
        <div className="container mx-auto px-4">
          <div className="detailPageBanner">
            <div className="detailBannerinfo">
              <h3 className="inner-page-title">
                Health Data Monitoring and Analysis Platform
              </h3>
              <p>
                HeadsUp is a complete combination of Web and Mobile Apps to
                track all your Health Records no matter if it is a Medical
                Record or Workout Records or Day to Day activities.
              </p>
            </div>
            <div className="detailBannerPic !items-end">
              <img src={BannerImage} alt="Banner" className="max-w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center md:text-left wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Project Overview
          </div>
          <div
            className="section-sub-title !font-normal wow animate__animated animate__fadeInUp"
            data-wow-delay="0.4s"
          >
            HeadsUp is a complete combination of Web and Mobile Apps to track
            all your Health Records no matter if it is a Medical Record or
            Workout Records or Day to Day activities. The HeadsUp allows a user
            to connect various fitness devices (e.g. Fitbit, Apple Watch, Keto
            Mojo), fitness accounts (e.g. Cronometer, MyFitnessPal), and medical
            information (e.g. medical records, genetic tests, lab tests) to
            learn from and monitor their data in one place.
          </div>
          <div
            className="section-title text-center md:text-left mt-[40px] lg:mt-[60px] xl:mt-[80px] wow animate__animated animate__fadeInUp"
            data-wow-delay="0.5s"
          >
            Business Goal
          </div>
          <div
            className="section-sub-title !font-normal wow animate__animated animate__fadeInUp"
            data-wow-delay="0.6s"
          >
            It was very difficult for client to track, manage and monitor his
            health and fitness data at one place. He even tried to use few
            available mobile and web app in the market but none of them
            fulfilled his idea completely. Client has come up with the idea to
            manage all the Records related to Health and Fitness at one place.
            Also, more interesting in his idea was to fetch the fitness data
            from different almost all kind of fitness devices and bands. Keeping
            track of the Fitness Goals in different durations is very necessary
            and client wanted to include that as well.
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] bg-[#E7E8F6]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Key Features
          </div>
          <div className="key-feature-row">
            <div className="key-feature-pic">
              <img src={headsupKey1} alt="" />
              <div className="key-image-bg"></div>
            </div>
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Dashboard Screen
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                User can access all the Fitness Data such as Steps, Time asleep,
                calories consumed, Body Fat and more through Dashboard with the
                option to add new fitness or remove any data on the Dashboard.
              </p>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Source of Data Screen
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                HeadsUp Health provides the privilege to the app users to select
                the source of information for their health data. The users can
                also add/edit/delete the manually added data.
              </p>
            </div>
            <div className="key-feature-pic">
              <img src={headsupKey2} alt="" />
              <div className="key-image-bg"></div>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-pic">
              <img src={headsupKey3} alt="" />
              <div className="key-image-bg"></div>
            </div>
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Manage Themes Screen
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                You can have different themes for the different matrices/health
                data. The user can change the themes as per their choices.
              </p>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.5s"
              >
                It also allows the Application users to edit application theme.
                It can either be - Day, Night and Auto. Auto will change the
                theme as per time of the day and night.
              </p>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Subscribe Screen
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                HeadsUp Health allows the user to manage their subscription. The
                user can subscribe to explore more new features.
              </p>
            </div>
            <div className="key-feature-pic">
              <img src={headsupKey4} alt="" />
              <div className="key-image-bg"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Our Process
          </div>
          <OurProcess />
        </div>
      </div>
      <div className="casestudy-challenges relative z-1 bg-[#222336]">
        <div className="container mx-auto px-4">
          <div className="challenge-content">
            <div
              className="section-title !text-white wow animate__animated animate__fadeInUp"
              data-wow-delay="0.3s"
            >
              Major Challenges
            </div>
            <ul className="challenge-list xl:-mt-[10px]">
              <li
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                The major challenge for any user is to keep all the Health
                Related records at one place. There are many applications in
                market that either allows you to keep the track of fitness goals
                and daily activities or only Medical Records. But the HeadsUp
                App is the combination of both making it easy for anybody like
                you and me to manage all data at one place and keep track of it.
              </li>
            </ul>
          </div>
        </div>
        <img src={challengePicture} alt="" className="challenge-picture" />
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] bg-[#FAFAFA]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Technology Stack
          </div>
          <Slider {...technologyStack} className="technology-stack-slider">
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon1} alt="" />
              </div>
              <h4>React Native</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon2} alt="" />
              </div>
              <h4>React JS</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon3} alt="" />
              </div>
              <h4>AI/ML</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon4} alt="" />
              </div>
              <h4>AWS</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon5} alt="" />
              </div>
              <h4>Jmeter</h4>
            </div>
          </Slider>
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default Headsup;
