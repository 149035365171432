import React, { useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import "animate.css/animate.min.css";

const client1 =
  "https://images.reactbytes.com/react-bytes/client-logo2-hover.svg";
const client2 =
  "https://images.reactbytes.com/react-bytes/client-logo3-loreal.png";
const client3 =
  "https://images.reactbytes.com/react-bytes/client-logo9-hover.svg";
const client4 =
  "https://images.reactbytes.com/react-bytes/client-logo11-hover.svg";
const client5 =
  "https://images.reactbytes.com/react-bytes/client-logo-medeloop-hover.svg";
const client6 =
  "https://images.reactbytes.com/react-bytes/client-logo10-hover.svg";
const client7 =
  "https://images.reactbytes.com/react-bytes/client-logo1-hover.svg";
const client8 =
  "https://images.reactbytes.com/react-bytes/client-logo4-hover.svg";
const client9 =
  "https://images.reactbytes.com/react-bytes/client-logo8-hover.svg";
const client10 =
  "https://images.reactbytes.com/react-bytes/client-logo5-hover.svg";
const client11 =
  "https://images.reactbytes.com/react-bytes/client-logo12-hover.svg";
const client12 =
  "https://images.reactbytes.com/react-bytes/client-logo13-hover.svg";
const client13 =
  "https://images.reactbytes.com/react-bytes/client-logo7-hover.svg";
const client14 =
  "https://images.reactbytes.com/react-bytes/client-logo14-hover.svg";

const FeaturedClients = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);

  const featuredClients = [
    client1,
    client2,
    client3,
    client4,
    client5,
    client6,
    client7,
    client8,
    client9,
    client10,
    client11,
    client12,
    client13,
    client14,
  ];

  return (
    <>
      <div className="bg-[#F8F9FA] py-[40px] md:py-[60px] lg:py-[100px]">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center justify-between gap-4 mb-[40px] lg:mb-[60px]">
            <div
              className="section-title wow animate__animated animate__fadeInUp"
              data-wow-delay="0.3s"
            >
              Featured Clients
            </div>
            <Link
              to={process.env.PUBLIC_URL + "/contact-us"}
              className="btn-black !hidden md:!inline-flex"
            >
              Contact Us
            </Link>
          </div>
          <ul className="featured-clients">
            {featuredClients.map((data, i) => (
              <li key={i}>
                <img src={data} alt="" />
              </li>
            ))}
          </ul>
          <div className="text-center inline-flex justify-center md:hidden w-full mt-5">
            <Link
              to={process.env.PUBLIC_URL + "/contact-us"}
              className="btn-black"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedClients;
