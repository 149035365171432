import React, { useEffect, useLayoutEffect, useState } from "react";
import "animate.css/animate.min.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const client1 = "https://images.reactbytes.com/react-bytes/client1.jpg";
const client2 = "https://images.reactbytes.com/react-bytes/client2.jpg";
const client3 = "https://images.reactbytes.com/react-bytes/client3.jpg";
const client4 = "https://images.reactbytes.com/react-bytes/client4.jpg";
const client5 = "https://images.reactbytes.com/react-bytes/client5.jpg";
const client6 = "https://images.reactbytes.com/react-bytes/client6.jpg";
const client7 = "https://images.reactbytes.com/react-bytes/client7.jpg";
const client8 = "https://images.reactbytes.com/react-bytes/client8.jpg";
const client9 = "https://images.reactbytes.com/react-bytes/client9.jpg";
const videoPlay = "https://images.reactbytes.com/react-bytes/video-play.svg";
const alyssaFranks =
  "https://images.reactbytes.com/react-bytes/alyssa-franks.webp";
const sisiChen =
  "https://images.reactbytes.com/react-bytes/testimonial-rahman-khan-sisi-chen.webp";
const michaelBucci =
  "https://images.reactbytes.com/react-bytes/testimonial-michael-bucci.jpg";
const gKim = "https://images.reactbytes.com/react-bytes/client9.jpg";
const joshuaStern =
  "https://images.reactbytes.com/react-bytes/joshua-stern.jpg";
const silvioLeoni =
  "https://images.reactbytes.com/react-bytes/silvio-leoni.png";
const max = "https://images.reactbytes.com/react-bytes/client5.jpg";
const davidStockelberg =
  "https://images.reactbytes.com/react-bytes/david-stockelberg.png";
const josephRenzi = "https://images.reactbytes.com/react-bytes/client4.jpg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96%",
  maxWidth: "800px",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Testimonials = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);

  var clientSliderSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [open, setOpen] = useState(false);
  const [testiLink, setTestiLink] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const testimonialSlider = [
    {
      clientPic: client1,
      videoPlayIcon: videoPlay,
      clientVideo: "https://www.youtube.com/embed/D0lR35w2E7M",
      clientThumb: alyssaFranks,
      clientName: "Alyssa Franks",
      clientDesg: "Co-founder Of Mfa App",
      clientDisc:
        "I am so glad, and so grateful that we partnered with ReactBytes. They have been with us from the beginning, looking at our very basic sketches and bringing all of that into a reality. The things I've loved best about working with ReactBytes is that the communication has been consistent, reliable and fast.",
    },
    {
      clientPic: client2,
      videoPlayIcon: videoPlay,
      clientVideo: "https://www.youtube.com/embed/_EwwErZdIYE",
      clientThumb: joshuaStern,
      clientName: "Joshua Stern",
      clientDesg: "CEO & CO-FOUNDER OF WINENDINE",
      clientDisc:
        "I've been working with Tristate now for almost two years. It's been a pleasure. They've been fantastic. Their developers are strong. Project management is great, they're reliable. If anything comes up, it gets taken care of right away and I can't recommend them enough.",
    },
    {
      clientPic: client3,
      videoPlayIcon: videoPlay,
      clientVideo: "https://www.youtube.com/embed/jF-mqf1UJOw",
      clientThumb: sisiChen,
      clientName: "Sisi Chen",
      clientDesg: "CEO & CO-FOUNDER",
      clientDisc:
        "I am Sisi from Netherland and we are very happy that we found TriState Technology LLP. They have developed our Android App, which is now online in Google Play Store. Our contact person Jay was professional, very flexible, he was also very easy to work. The development team did a very good job by completing the app, applying our questions, finding solutions that we have faced with the previous team. Even though there was the time difference, the communication was flexible. We are hoping that Android App would be a super success and will ask TriState to develop the iOS version App too.",
    },
    {
      clientPic: client4,
      clientThumb: josephRenzi,
      clientName: "Joseph Renzi",
      clientDisc:
        "One of the great things about ReactBytes is that they were always happy to do whatever they could to make sure that we were happy. They had achieved the requirements at a high quality.",
    },
    {
      clientPic: client5,
      clientThumb: max,
      clientName: "MAX",
      clientDisc:
        "Hemant and the entire team at ReactBytes are great to deal with. They are incredibly efficient, deliver high-end results and were transparent and honest throughout the entire process. For anyone looking for digital services, I would strongly recommend them.",
    },
    {
      clientPic: client6,
      clientThumb: davidStockelberg,
      clientName: "David Stockelberg",
      clientDesg: "FOUNDER",
      clientDisc:
        "I was very happy with what was done for us. Great quality of work and communication skills.",
    },
    {
      clientPic: client7,
      videoPlayIcon: videoPlay,
      clientVideo: "https://www.youtube.com/embed/de4c3dJMG-8",
      clientThumb: silvioLeoni,
      clientName: "Silvio Leoni",
      clientDesg: "CO-FOUNDER",
      clientDisc:
        "We enjoyed working with TriState, they are very professional, respond fast and are flexible to additional querries. We will continue to work together in future projects. Thank you for the good work!”",
    },
    {
      clientPic: client8,
      videoPlayIcon: videoPlay,
      clientVideo: "https://www.youtube.com/embed/EOiouczKY5s",
      clientThumb: michaelBucci,
      clientName: "Michael Bucci",
      clientDesg: "FOUNDER",
      clientDisc:
        "I was super happy with the finished application. Everything turned out the way we liked it. They always stay up to date with the deadlines. Overall my experience with them was fantastic. I would 100% work with then again.",
    },
    {
      clientPic: client9,
      clientThumb: gKim,
      clientName: "DR. G Kim",
      clientDesg: "Kim Orthodontics LLC",
      clientDisc:
        "The team @ TriState Technology are very honest, helpful, and knowledgeable. They were familiar with the latest developer rules from Apple and Google and guided me to setup all the necessary accounts I needed for both the Apple Store, Google Play Store, and website hosting. They are very agreeable and communication was very open during the entire project. Thank you TriState!",
    },
  ];

  return (
    <>
      <div className="py-[40px] md:py-[60px] lg:py-[80px]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            What our clients say
          </div>
          <Slider {...clientSliderSettings} className="clients-say-slider">
            {testimonialSlider.map((data, i) => (
              <div className="clients-say-item" key={i}>
                <div className="clients-say-user">
                  <img src={data.clientPic} alt="" className="client-pic" />
                  <img
                    src={data.videoPlayIcon}
                    alt=""
                    className="videoplay"
                    onClick={() => {
                      handleOpen();
                      setTestiLink(data.clientVideo);
                    }}
                  />
                </div>
                <div className="clients-say-user-info">
                  <div className="clients-say-user-name">
                    <img
                      src={data.clientThumb}
                      alt=""
                      className="client-user-thumb"
                    />
                    <div className="user-info">
                      <h3>{data.clientName}</h3>
                      <span>{data.clientDesg}</span>
                    </div>
                  </div>
                  <p>{data.clientDisc}</p>
                </div>
              </div>
            ))}
          </Slider>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <iframe
                title="Video"
                className="testimonial-video"
                width="100%"
                height="400"
                src={testiLink}
                allow="accelerometer; autoplay; clipboard-write;
                encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
