import React from "react";
import "../style/spinner.css";
import { BounceLoader } from "react-spinners";

const Spinner = () => {
  return (
    <>
      <div className="loaderFull">
        {/* <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
        <BounceLoader size={100} color="#4aaad3" />
      </div>
    </>
  );
};

export default Spinner;
