import React, { useEffect, useLayoutEffect } from "react";
import "isomorphic-fetch";
import Header from "./include/Header";
import Footer from "./include/Footer";
import ScrollToTop from "./Component/ScrollTotop";
import "animate.css/animate.min.css";
import MetaTags from "./Component/MetaTags ";
import config from "../utils/config";
import * as Constant from "../utils/constant";

const thankYou = "https://images.reactbytes.com/react-bytes/thank-you-img.jpg";
const Thankyou = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);
  return (
    <>
      <div className="headerFixed">
        <Header />
        <MetaTags
          title="ReactJS Development Company, React Native Development Services for Web and Mobile"
          description="We are ReactJS Development Company based in India, offers React Native Development and ReactJS Development Services for Websites and Mobile Apps. Get a Free Quote and Hire ReactJS Expert from ReactBytes."
          keywords="ReactJS Development Company, React Native Development, ReactJS Development Services, ReactJS for Web Development"
          ogImage={config.LIVE_IMAGE_BASE_URL + "landing-page-og-img.jpg"}
          ogURL={Constant.WEBSITE_URL + "/thank-you"}
          author={Constant.WEBSITE_AUTHOR_URL}
          facebookURL={Constant.FACEBOOK_URL}
          twitterSite={Constant.TWITTER_PAGE_CREATOR}
          twitterCreator={Constant.TWITTER_PAGE_CREATOR}
        ></MetaTags>
      </div>
      <div className="container mx-auto px-4">
        <div className="thank-you-content">
          <img src={thankYou} alt="Thank You" />
          <p
            className="wow animate__animated animate__fadeInUp"
            data-wow-delay="0.4s"
          >
            One of our Experts will contact you shortly to discuss your project
            & run you through a free consultation
          </p>
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default Thankyou;
