export const Graph_api = "https://www.reactbytes.com/reactbytes-blog/graphql";

export const blogs = `{
  GET_POSTS {
      posts(first: 3) {
          edges {
              node {
                  id
                  postId
                  title
                  excerpt(format:RAW)
                  date
                  slug
                  featuredImage{
                  sourceUrl
                  }
              }  
          }
      }
  }
}`;

export const validateSchema = (error) => {
  let msg;
  console.log(
    "error.details[0]---type---key ",
    error.details[0].type.includes("allowOnly")
  );

  let key = error.details[0].context.label || error.details[0].context.key;
  if (error.details[0].type.includes("empty")) {
    if (error.details[0].path.length > 1) {
      msg = error.details[0].path[0].replace(/_/g, " ") + " is required !";
      msg = msg.charAt(0).toUpperCase() + msg.slice(1);
    } else {
      msg = key.replace(/_/g, " ") + " is required!";
      msg = msg.charAt(0).toUpperCase() + msg.slice(1);
    }
  } else if (error.details[0].type.includes("string.min")) {
    msg =
      key.replace(/_/g, " ") +
      " length must be at least " +
      error.details[0].context.limit +
      " characters long !";
    msg = msg.charAt(0).toUpperCase() + msg.slice(1);
  } else if (error.details[0].type.includes("string.max")) {
    msg =
      key.replace(/_/g, " ") +
      " length must be less than or equal to " +
      error.details[0].context.limit +
      " characters long !";
    msg = msg.charAt(0).toUpperCase() + msg.slice(1);
  } else if (error.details[0].type.includes("number.min")) {
    msg =
      key.replace(/_/g, " ") +
      " should be greater than or equal to " +
      error.details[0].context.limit;
    msg = msg.charAt(0).toUpperCase() + msg.slice(1);
  } else if (error.details[0].type.includes("number.max")) {
    msg =
      key.replace(/_/g, " ") +
      " should be less than or equal to " +
      error.details[0].context.limit;
    msg = msg.charAt(0).toUpperCase() + msg.slice(1);
  } else if (error.details[0].type.includes("allowOnly")) {
    if (
      error.details[0].context.key === "confirm_password" ||
      error.details[0].context.key === "new_password"
    ) {
      msg = "New Password and confirm password must be same !";
      msg = msg.charAt(0).toUpperCase() + msg.slice(1);
    } else {
      msg = "Password and confirm password must be same !";
      msg = msg.charAt(0).toUpperCase() + msg.slice(1);
    }
  } else {
    msg = "Please enter a valid " + key.replace(/_/g, " ") + " !";
  }
  let result = { error: msg, errorField: error.details[0].context.key };
  return result;
};

// export const EMAIL_REGEX1 = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_REGEX1 =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const CONTACT_REGEX =
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
