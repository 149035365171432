import { React } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import ReactJsDevelopment from "./Pages/ReactJs";
import ReactNative from "./Pages/ReactNative";
import NotFoundPage from "./Pages/404";
import Blog from "./Pages/Blog/Blog";
import Insight from "./Pages/Component/Insight";
import BlogDetail from "./Pages/Blog/Blogdetail";
import Thankyou from "./Pages/thank-you";
import Contactwithoutheader from "./Pages/ContactWithoutHeader";
import ReactGA from "react-ga";
import Portfolio from "./Pages/Portfolio";
import TestimonialsPage from "./Pages/Testimonials";
import HireReactNativeDeveloper from "./Pages/hire-react-native-developer";
import HireReactJSDeveloper from "./Pages/hire-reactjs-developer";

import Leva from "./Pages/Casestudy/leva";
import Headsup from "./Pages/Casestudy/headsup";
import Investfar from "./Pages/Casestudy/investfar";
import GiftCenter from "./Pages/Casestudy/gift-center";
import Loggy from "./Pages/Casestudy/loggy";

const TRACKING_ID = "UA-135692745-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path={process.env.PUBLIC_URL + "/"} element={<Home />} />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/portfolio"}
          element={<Portfolio />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/contact-us"}
          element={<Contact />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/reactjs-development"}
          element={<ReactJsDevelopment />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/react-native-development"}
          element={<ReactNative />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/hire-reactjs-developer"}
          element={<HireReactJSDeveloper />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/hire-react-native-developer"}
          element={<HireReactNativeDeveloper />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/testimonials"}
          element={<TestimonialsPage />}
        />
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to={"/404"} />} />

        <Route
          exact
          path={process.env.PUBLIC_URL + "/blog"}
          element={<Blog />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/blog/:slug"}
          element={<BlogDetail />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/insight"}
          element={<Insight />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/thank-you"}
          element={<Thankyou />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/without"}
          element={<Contactwithoutheader />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/casestudy/leva"}
          element={<Leva />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/casestudy/headsup"}
          element={<Headsup />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/casestudy/investfar"}
          element={<Investfar />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/casestudy/gift-center"}
          element={<GiftCenter />}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/casestudy/loggy"}
          element={<Loggy />}
        />
      </Routes>
    </Router>
  );
}

export default App;
