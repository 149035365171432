import { React, useEffect, useLayoutEffect } from "react";
import Header from "../include/Header";
import Footer from "../include/Footer";
import ScrollToTop from "../Component/ScrollTotop";
import "animate.css/animate.min.css";
import config from "../../utils/config";
import * as Constant from "../../utils/constant";
import "../../Pages/Casestudy/css/casestudy-style.css";
import "../../Pages/Casestudy/css/casestudy-media.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OurProcess from "./Component/OurProcess";
import MetaTags from "../Component/MetaTags ";

const casestudyBannerBg =
  "https://images.reactbytes.com/react-bytes/leva-banner-bg.jpg";
const BannerImage = "https://images.reactbytes.com/react-bytes/leva-banner.png";
const challengePicture =
  "https://images.reactbytes.com/react-bytes/leva-challenges.png";
const clientReviewProfile =
  "https://images.reactbytes.com/react-bytes/vanessa-jupe.jpg";
const levaGrowthTracking =
  "https://images.reactbytes.com/react-bytes/leva-growth-tracking.png";
const levaFeedingDiaperTracking =
  "https://images.reactbytes.com/react-bytes/leva-feeding-diaper.png";
const levaFindConsultant =
  "https://images.reactbytes.com/react-bytes/leva-find-consultant.png";
const levaDevelopmentalMilestones =
  "https://images.reactbytes.com/react-bytes/leva-milestones.png";
const levaGrowthChartMonitoring =
  "https://images.reactbytes.com/react-bytes/leva-chart-monitoring.png";
const levaHealthJournal =
  "https://images.reactbytes.com/react-bytes/leva-health-journal.png";
const technologyIcon1 =
  "https://images.reactbytes.com/react-bytes/reactjs-icon.svg";
const technologyIcon2 =
  "https://images.reactbytes.com/react-bytes/JavaScript-logo.png";
const technologyIcon3 =
  "https://images.reactbytes.com/react-bytes/node.js_logo.png";
const technologyIcon4 =
  "https://images.reactbytes.com/react-bytes/postgre-sql-logo.png";
const technologyIcon5 =
  "https://images.reactbytes.com/react-bytes/aws-icon.png";
const Leva = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);
  var technologyStack = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <div className="innerPageHeader casesudyBanner">
        <img
          src={casestudyBannerBg}
          alt="Banner"
          className="casestudyBannerBg"
        />
        <Header />
        <MetaTags
          title="Leva App Case Study | Health & Development Tracking for Moms & Babies"
          description="Discover how Leva's mobile solution revolutionizes health and development tracking for new moms and babies. Learn about the innovative features and success."
          keywords="Leva App, Health Tracking App, Case Study, Mobile Solution, Innovative Features,"
          ogImage={config.LIVE_IMAGE_BASE_URL + "leva-work-og-img.jpg"}
          ogURL={Constant.WEBSITE_URL + "/casestudy/leva"}
          author={Constant.WEBSITE_AUTHOR_URL}
          facebookURL={Constant.FACEBOOK_URL}
          twitterSite={Constant.TWITTER_PAGE_CREATOR}
          twitterCreator={Constant.TWITTER_PAGE_CREATOR}
        ></MetaTags>
        <div className="container mx-auto px-4">
          <div className="detailPageBanner">
            <div className="detailBannerinfo">
              <h3 className="inner-page-title">
                Mobile Solution for Tracking Health and Development of New Mom &
                Baby
              </h3>
              <p>
                The mobile application caters the needs of new mothers in
                tracking and managing their baby's health and development.
              </p>
              {/* <Link to={process.env.PUBLIC_URL + ""} className="default-btn">
                Know More From Our Experts
              </Link> */}
            </div>
            <div className="detailBannerPic !items-end">
              <img src={BannerImage} alt="Banner" className="max-w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center md:text-left wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Project Overview
          </div>
          <div
            className="section-sub-title !font-normal wow animate__animated animate__fadeInUp"
            data-wow-delay="0.4s"
          >
            'Leva App' is designed for women who are in the pregnancy stage or
            have a baby who is born. The aim of making this app is that the
            mother can track the time and growth of Breastfeeding, bottling,
            diaper, and pumping. Mothers can also see videos and articles about
            their related interests. Mothers can see the graphical view of the
            growth and also compare the max and min growth of the child which is
            given as per the government guidelines.
          </div>
          <div
            className="section-title text-center md:text-left mt-[40px] lg:mt-[60px] xl:mt-[80px] wow animate__animated animate__fadeInUp"
            data-wow-delay="0.5s"
          >
            Introduction
          </div>
          <div
            className="section-sub-title !font-normal wow animate__animated animate__fadeInUp"
            data-wow-delay="0.6s"
          >
            The Leva App was developed as a comprehensive mobile application
            catering to the needs of new mothers in tracking and managing their
            baby's health and development. It offers a range of features
            designed to provide guidance and support during the crucial early
            stages of a baby's life.
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] bg-[#FEF5F5]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Key Features
          </div>
          <div
            className="section-sub-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.4s"
          >
            The Leva App offers a comprehensive set of features to assist
            mothers in tracking and
            <br /> managing their baby's health. Some of the key features
            include:
          </div>
          <div className="key-feature-row">
            <div className="key-feature-pic">
              <img src={levaGrowthTracking} alt="" />
              <div className="key-image-bg"></div>
            </div>
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Growth Tracking
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                The app enables mothers to record and monitor their baby's
                growth parameters such as weight, height, and head
                circumference. It provides visual representations of growth
                trends and compares them to standard growth charts, helping
                mothers identify any potential issues.
              </p>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Feeding and Diaper Tracking
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                Leva App allows mothers to log and track their baby's feeding
                schedules, including breastfeeding, and bottle feeding. It also
                enables them to monitor diaper changes, helping identify any
                irregularities or potential health concerns.
              </p>
            </div>
            <div className="key-feature-pic">
              <img src={levaFeedingDiaperTracking} alt="" />
              <div className="key-image-bg"></div>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-pic">
              <img src={levaFindConsultant} alt="" />
              <div className="key-image-bg"></div>
            </div>
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Find Consultant
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                The app provides the feature for the video call session for
                taking guidance from a well-reputed consultant.
              </p>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Growth Chart Monitoring
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                Leva App includes a feature of a growth chart in which the
                mother can see the graphical view of their child's weight,
                height, and head size. You can also see charts on weekly and
                monthly bases.
              </p>
            </div>
            <div className="key-feature-pic">
              <img src={levaGrowthChartMonitoring} alt="" />
              <div className="key-image-bg"></div>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-pic">
              <img src={levaDevelopmentalMilestones} alt="" />
              <div className="key-image-bg"></div>
            </div>
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Developmental Milestones
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                The app offers a comprehensive list of developmental milestones,
                guiding mothers through each stage of their baby's growth. It
                provides age-appropriate milestones and tips to encourage and
                support their baby's development.
              </p>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Health Journal
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                Leva App provides a digital health journal where mothers can
                record and store important health-related information, including
                doctor's appointments, medications, allergies, and illnesses.
                This feature ensures that all essential health data is easily
                accessible and organized in one place.
              </p>
            </div>
            <div className="key-feature-pic">
              <img src={levaHealthJournal} alt="" />
              <div className="key-image-bg"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Our Process
          </div>
          <OurProcess />
        </div>
      </div>
      <div className="casestudy-challenges relative z-1 bg-[#222336]">
        <div className="container mx-auto px-4">
          <div className="challenge-content">
            <div
              className="section-title !text-white wow animate__animated animate__fadeInUp"
              data-wow-delay="0.3s"
            >
              Challenges Faced
            </div>
            <ul className="challenge-list xl:-mt-[10px]">
              <li
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                In the app, we have proved discounts that will be purchased from
                Google in that we have done customer promo code management
                custom promo code management is a very challenging part for us.
              </li>
              <li
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                Multiple time zone the client is facing issues in their time
                zone so we have to provide it.
              </li>
              <li
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.5s"
              >
                Google tag management for marketing purposes.
              </li>
              <li
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.6s"
              >
                In the app we have used multiple bookings so in that we have
                used Calendly but we are facing issues with multiple bookings so
                we have done it with Webhook and managed the multiple booking.
              </li>
            </ul>
          </div>
        </div>
        <img src={challengePicture} alt="" className="challenge-picture" />
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] bg-[#FAFAFA]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Technology Stack
          </div>
          <Slider {...technologyStack} className="technology-stack-slider">
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon1} alt="" />
              </div>
              <h4>React Native</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon2} alt="" />
              </div>
              <h4>JavaScript</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon1} alt="" />
              </div>
              <h4>React JS</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon3} alt="" />
              </div>
              <h4>Node JS</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon4} alt="" />
              </div>
              <h4>PostgreSQL</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon5} alt="" />
              </div>
              <h4>AWS</h4>
            </div>
          </Slider>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] text-center">
        <div className="container mx-auto px-4">
          <div
            className="section-title wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Conclusion
          </div>
          <div
            className="section-sub-title xl:px-[14%] 2xl:px-[17.5%] wow animate__animated animate__fadeInUp"
            data-wow-delay="0.4s"
          >
            The Leva App demonstrates the potential of mobile applications in
            empowering and supporting new mothers in their parenting journey. By
            providing comprehensive tracking features, evidence-based
            information, and personalized insights, Leva App has positively
            impacted the lives of mothers and their babies. With continued
            innovation and enhancement, the app has the potential to become an
            essential tool for new mothers worldwide, fostering healthy
            development and well-being for both mother and child.
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] bg-[#FAFAFA]">
        <div className="container mx-auto px-4">
          <div className="casestudy-review">
            <div className="case-client-profile">
              <img src={clientReviewProfile} alt="" />
              <div className="quote">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.846 18.4619H8.46136C7.82029 18.4619 7.27529 18.2376 6.82653 17.7888C6.37802 17.3401 6.15346 16.7952 6.15346 16.154V15.3851C6.15346 13.6863 6.75454 12.2361 7.95661 11.034C9.15859 9.83238 10.609 9.2313 12.3077 9.2313H13.846C14.2626 9.2313 14.6231 9.07899 14.9277 8.77462C15.2321 8.47008 15.3844 8.10964 15.3844 7.69295V4.61575C15.3844 4.19915 15.2321 3.83837 14.9277 3.53383C14.6232 3.22971 14.2627 3.07715 13.846 3.07715H12.3077C10.6409 3.07715 9.05065 3.40198 7.53605 4.05071C6.02161 4.69986 4.71169 5.57735 3.60578 6.68327C2.49995 7.78868 1.62271 9.09869 0.973642 10.6133C0.324576 12.1277 0 13.7184 0 15.385V32.3077C0 33.5904 0.448512 34.6796 1.34596 35.5774C2.24349 36.4747 3.33349 36.9233 4.61546 36.9233H13.8466C15.1285 36.9233 16.2182 36.4747 17.1158 35.5774C18.0131 34.6796 18.4618 33.5904 18.4618 32.3077V23.0774C18.4618 21.7949 18.0131 20.7057 17.1152 19.8079C16.218 18.9107 15.1279 18.4619 13.846 18.4619Z"
                    fill="#03AAD4"
                  />
                  <path
                    d="M38.6547 19.8079C37.7575 18.9107 36.6677 18.4619 35.3855 18.4619H30.0009C29.3602 18.4619 28.8147 18.2376 28.3667 17.7888C27.9177 17.3401 27.6936 16.7952 27.6936 16.154V15.3851C27.6936 13.6863 28.2947 12.2361 29.4962 11.034C30.6977 9.83238 32.1481 9.2313 33.8476 9.2313H35.3856C35.8023 9.2313 36.163 9.07899 36.4673 8.77462C36.7715 8.47008 36.9244 8.10964 36.9244 7.69295V4.61575C36.9244 4.19915 36.7716 3.83837 36.4673 3.53383C36.1631 3.22971 35.8024 3.07715 35.3856 3.07715H33.8476C32.1798 3.07715 30.59 3.40198 29.0749 4.05071C27.5608 4.69986 26.2513 5.57735 25.1453 6.68327C24.0394 7.78868 23.1618 9.09869 22.513 10.6133C21.8641 12.1277 21.5391 13.7184 21.5391 15.385V32.3077C21.5391 33.5904 21.988 34.6796 22.8852 35.5774C23.7825 36.4747 24.8722 36.9233 26.1543 36.9233H35.385C36.6672 36.9233 37.7569 36.4747 38.6541 35.5774C39.552 34.6796 40 33.5904 40 32.3077V23.0774C40 21.7948 39.552 20.7057 38.6547 19.8079Z"
                    fill="#03AAD4"
                  />
                </svg>
              </div>
            </div>
            <div className="case-client-review text-center md:text-left">
              <div
                className="section-title wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Client Review
              </div>
              <div
                className="section-sub-title wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                The team @ TriState Technology are very honest, helpful, and
                knowledgeable. They were familiar with the latest developer
                rules from Apple and Google and guided me to setup all the
                necessary accounts I needed for both the Apple Store, Google
                Play Store, and website hosting. They are very agreeable and
                communication was very open during the entire project. Thank you
                TriState!
              </div>
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.5s"
              >
                Vanessa Jupe
              </h3>
              <span
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.6s"
              >
                Founder & CEO at Leva
              </span>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default Leva;
