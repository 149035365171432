import React, { useEffect, useLayoutEffect } from "react";
import "animate.css/animate.min.css";
import ContactForm from "./ContactForm";

const GetTouch = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);

  return (
    <>
      <div className="bg-[#F8F9FA] py-[40px] md:py-[0px]">
        <div className="container mx-auto px-4">
          <div className="get-touch-content">
            <div className="get-touch-info">
              <div
                className="section-title wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Get in touch with us
              </div>
              <div
                className="section-sub-title wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                Once you fill out this form, our sales representatives will
                contact you within 24 hours.
              </div>
              <ul>
                <li>
                  Our sales manager will get in touch with you to discuss your
                  business idea in details within 1 day
                </li>
                <li>
                  We will analyse your requirements, prepare project estimation,
                  approximate timeline and propose what we can offer to meet
                  your needs
                </li>
                <li>
                  Now, if you are ready to turn your idea into action, we will
                  sign a contract that is complying with your local laws & see
                  how your idea becomes a real product
                </li>
              </ul>
            </div>
            <div className="get-touch-form">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetTouch;
