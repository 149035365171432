import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faMobile } from "@fortawesome/free-solid-svg-icons";

const Logo = "https://images.reactbytes.com/react-bytes/reactbytes-logo.svg";
const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="container mx-auto px-4">
          <div className="py-[40px] md:py-[60px] lg:py-[80px] footer-content">
            <div className="footer-left">
              <Link to={process.env.PUBLIC_URL + "/"}>
                <img src={Logo} alt="" className="logo-white" />
              </Link>
              <h3>React App Development Agency</h3>
              <p>
                Transforming Digital Solutions using React.js and React Native
              </p>
              <ul>
                <li>
                  <Link
                    to="https://www.facebook.com/reactbytes"
                    target="__blank"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Link>
                </li>
                <li>
                  <Link to="https://x.com/ReactBytes" target="__blank">
                    <FontAwesomeIcon icon={faXTwitter} />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/reactbytes/"
                    target="__blank"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </Link>
                </li>
                {/* <li>
                  <Link to={process.env.PUBLIC_URL + "/"} target="__blank">
                    <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="footer-links">
              <h3>Quick Links</h3>
              <ul>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/portfolio"}>
                    Portfolio
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/testimonials"}>
                    Testimonials
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/blog"}>Insight</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/contact-us"}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-links">
              <h3>Services</h3>
              <ul>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/reactjs-development"}>
                    React.js Development
                  </Link>
                </li>
                <li>
                  <Link
                    to={process.env.PUBLIC_URL + "/react-native-development"}
                  >
                    React Native Development
                  </Link>
                </li>
                <li>
                  <Link
                    to={process.env.PUBLIC_URL + "/hire-react-js-development"}
                  >
                    Hire React JS Developer
                  </Link>
                </li>
                <li>
                  <Link
                    to={
                      process.env.PUBLIC_URL + "/hire-react-native-development"
                    }
                  >
                    Hire React Native Developer
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-links contact-links">
              <h3>Contact</h3>
              <ul className="!pl-0">
                <li>
                  <div className="icon">
                    <FontAwesomeIcon icon={faMobile} />
                  </div>
                  <a href="tel:+44 7884 120778">+44 7884 120778</a>
                </li>
                <li>
                  <div className="icon">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <a href="mailto:hello@reactbytes.com">hello@reactbytes.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          © 2012-2024 reactbytes All rights reserved.
        </div>
      </div>
    </>
  );
};

export default Footer;
