export const END_POINT_URL = "https://www.reactbytes.com/contact_action.php";
export const FACEBOOK_URL = "https://www.facebook.com/reactbytes/";
export const TWITTER_URL = "https://twitter.com/ReactBytes";
export const GITHUB_URL = "https://www.twitter.com";
export const INSTAGRAM_URL = "https://www.instagram.com";
export const GRAPHQL_URL = "https://www.reactbytes.com/reactbytes-blog/graphql";
export const REACT_BYTES_EMAIL = "hello@reactbytes.com";
export const GOOGLE_RECAPTCHA_SITE_KEY =
  "6LeSL5YUAAAAAGKwBUlkKt3qKArLqhWMUygeYZQ4";
export const TWITTER_PAGE_CREATOR = "@ReactBytes";
export const WEBSITE_AUTHOR_URL =
  "https://plus.google.com/117261035335164473119";
export const WEBSITE_URL = "https://www.reactbytes.com";
export const REACT_BYTES_CONTACTUS = "+44 7884 120778";
export const REACT_BYTES_BLOGS_BG =
  "https://images.reactbytes.com/react-bytes/og-images/blog-og-img.jpg";
