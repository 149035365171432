import { React, useEffect, useLayoutEffect } from "react";
import Header from "../include/Header";
import Footer from "../include/Footer";
import ScrollToTop from "../Component/ScrollTotop";
import "animate.css/animate.min.css";
import config from "../../utils/config";
import * as Constant from "../../utils/constant";
import "../../Pages/Casestudy/css/casestudy-style.css";
import "../../Pages/Casestudy/css/casestudy-media.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OurProcess from "./Component/OurProcess";
import MetaTags from "../Component/MetaTags ";

const BannerImage =
  "https://images.reactbytes.com/react-bytes/motologs-banner.png";
const motologsKey1 =
  "https://images.reactbytes.com/react-bytes/motologs-key1.png";
const motologsKey2 =
  "https://images.reactbytes.com/react-bytes/motologs-key2.png";
const motologsKey3 =
  "https://images.reactbytes.com/react-bytes/motologs-key3.png";
const motologsKey5 =
  "https://images.reactbytes.com/react-bytes/motologs-key5.png";
const motologsKey6 =
  "https://images.reactbytes.com/react-bytes/motologs-key6.png";
const technologyIcon1 =
  "https://images.reactbytes.com/react-bytes/reactjs-icon.svg";
const technologyIcon2 =
  "https://images.reactbytes.com/react-bytes/node.js_logo.png";
const technologyIcon3 =
  "https://images.reactbytes.com/react-bytes/postgre-sql-logo.png";
const technologyIcon5 =
  "https://images.reactbytes.com/react-bytes/aws-icon.png";
const technologyIcon6 =
  "https://images.reactbytes.com/react-bytes/aws-ses-icon.svg";
const Loggy = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);
  var technologyStack = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <div className="innerPageHeader casesudyBanner bg-[#232228]">
        {/* <img
          src={casestudyBannerBg}
          alt="Banner"
          className="casestudyBannerBg"
        /> */}
        <Header />
        <MetaTags
          title="Loggy App Case Study | Digital Logbook for Vehicle Management"
          description="Discover how Loggy's digital logbook simplifies vehicle management. Read the case study for insights into innovative features and success stories."
          keywords="Loggy app, Digital logbook for Vehicle Management, Case Study"
          ogImage={config.LIVE_IMAGE_BASE_URL + "loggy-work-og-img.jpg"}
          ogURL={Constant.WEBSITE_URL + "/casestudy/loggy"}
          author={Constant.WEBSITE_AUTHOR_URL}
          facebookURL={Constant.FACEBOOK_URL}
          twitterSite={Constant.TWITTER_PAGE_CREATOR}
          twitterCreator={Constant.TWITTER_PAGE_CREATOR}
        ></MetaTags>
        <div className="container mx-auto px-4">
          <div className="detailPageBanner">
            <div className="detailBannerinfo">
              <h3 className="inner-page-title">Digital Logbook for Vehicles</h3>
              <p>
                Whether you have a Car, Motorcycle, Boat, Heavy Vehicle, Plane
                or a Hobby vehicle Loggy helps you keep track of all the logs
                for your vehicle.
              </p>
            </div>
            <div className="detailBannerPic !items-end">
              <img src={BannerImage} alt="Banner" className="max-w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center md:text-left wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Project Overview
          </div>
          <div
            className="section-sub-title !font-normal wow animate__animated animate__fadeInUp"
            data-wow-delay="0.4s"
          >
            When you have a vehicle, it becomes hectic to manage so many things
            about it, like, insurance, repairs, time for service, etc. And if
            you have more than one vehicle then it gets even more hectic.
            Motolog is the app which solves the problem and helps you to keep
            track of all the data regarding your vehicles.
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] bg-[#fff1ed]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Key Features
          </div>
          <div className="key-feature-row">
            <div className="key-feature-pic">
              <img src={motologsKey1} alt="" />
              <div className="key-image-bg"></div>
            </div>
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Vehicle Management
              </h3>
              <ul className="mt-[10px]">
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  User can maintain their vehicle records by mentioning the
                  vehicle type, its make, its model, year and upload a photo.
                </li>
              </ul>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Maintain log for faults and old services
              </h3>
              <ul className="mt-[10px]">
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  User can manage logs for issues, repairs or services by
                  entering the type of log he is adding, some description and
                  date. The user can also upload an image, audio and attach a
                  file.
                </li>
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.5s"
                >
                  The user can mention the types of service and some major
                  issues solved in that service.
                </li>
              </ul>
            </div>
            <div className="key-feature-pic">
              <img src={motologsKey2} alt="" />
              <div className="key-image-bg"></div>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-pic">
              <img src={motologsKey3} alt="" />
              <div className="key-image-bg"></div>
            </div>
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Insurance Details Management
              </h3>
              <ul className="mt-[10px]">
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  User can add insurance related information for particular
                  vehicle.
                </li>
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.5s"
                >
                  Reminders for policy expiration.
                </li>
              </ul>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Offline Data Access
              </h3>
              <ul className="mt-[10px]">
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  Log and access data without internet connection.
                </li>
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.5s"
                >
                  Smart sync offline records with cloud database.
                </li>
              </ul>
            </div>
            <div className="key-feature-pic">
              <img src={motologsKey1} alt="" />
              <div className="key-image-bg"></div>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-pic">
              <img src={motologsKey5} alt="" />
              <div className="key-image-bg"></div>
            </div>
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Export Reports
              </h3>
              <ul className="mt-[10px]">
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  User can view various reports regarding their vehicle logs and
                  statistics.
                </li>
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.5s"
                >
                  Option to download and print reports.
                </li>
              </ul>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Transfer Vehicle Details
              </h3>
              <ul className="mt-[10px]">
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  User can transfer a vehicle details to another user by
                  mentioning the email id and the receiver can accept it.
                </li>
                <li
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.5s"
                >
                  Once this request is accepted then the details will be shown
                  in the receivers account.
                </li>
              </ul>
            </div>
            <div className="key-feature-pic">
              <img src={motologsKey6} alt="" />
              <div className="key-image-bg"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Our Process
          </div>
          <OurProcess />
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] bg-[#FAFAFA]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Technologies We Used
          </div>
          <Slider {...technologyStack} className="technology-stack-slider">
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon1} alt="" />
              </div>
              <h4>React Native</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon2} alt="" />
              </div>
              <h4>Node JS</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon3} alt="" />
              </div>
              <h4>PostgreSQL</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon5} alt="" />
              </div>
              <h4>AWS Cloud</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon6} alt="" />
              </div>
              <h4>AWS SES</h4>
            </div>
          </Slider>
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default Loggy;
