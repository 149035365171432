import React, { useState, createRef, useLayoutEffect, useEffect } from "react";
import "isomorphic-fetch";
import "animate.css/animate.min.css";

import * as Constant from "../../../src/utils/constant";
import Joi from "joi-browser";
import { validateSchema, EMAIL_REGEX1 } from "../../../src/Common/Common";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import useAnalyticsEventTracker from "../../Pages/useAnalyticsEventTracker";
import { TextField } from "@mui/material";

const ContactForm = () => {
  const navigate = useNavigate();
  const recaptchaRef = createRef();
  console.log("recaptchaRef", recaptchaRef);

  const gaEventTracker = useAnalyticsEventTracker("Contact us");

  const [formvalues, setformvalues] = useState({
    name: "",
    email: "",
    phonenumber: "",
    massage: "",
    captchaVerified: false,
  });
  console.log("formvalues", formvalues);

  const [errors, setErrors] = useState({
    error: "",
    errorField: "",
    ipAddress: "",
    captchaVerified: false,
    fields: {},
  });
  console.log("errors", errors);

  const HandleOnchange = (e) => {
    setformvalues({ ...formvalues, [e.target.name]: e.target.value });
  };
  const VerifyCallback = (recaptchaToken) => {
    setErrors((errors.captchaVerified = true));
    setformvalues({
      ...formvalues,
      captchaVerified: !formvalues?.captchaVerified,
    });
  };

  const HandleSubmit = () => {
    var params = [];
    params.push("ip=" + errors.ipAddress);
    params.push("name=" + formvalues.name);
    params.push("email=" + formvalues.email);
    params.push("phone=" + formvalues.phonenumber);
    params.push("msg=" + formvalues.massage);
    // params.push("captchaVerified=" + formvalues.captchaVerified);
    console.log("params", params);

    var queryParams = params.join("&");

    let Schema = Joi.object().keys({
      name: Joi.string().trim().required(),
      email: Joi.string().trim().regex(EMAIL_REGEX1).email().required(),
      phonenumber: Joi.optional(),
      massage: Joi.string().trim().required(),
      captchaVerified: Joi.boolean().valid(true),
    });

    Joi.validate(formvalues, Schema, (error) => {
      if (error) {
        if (
          error.details[0].message !== errors.error ||
          error.details[0].context.key !== errors.errorField
        ) {
          let errorLog = validateSchema(error);

          setErrors({ error: errorLog.error, errorField: errorLog.errorField });
        }
      } else {
        gaEventTracker("call");
        fetch(Constant.END_POINT_URL + "?" + queryParams).then((res) => {
          if (res.status === 200) {
            setformvalues({
              name: "",
              phonenumber: "",
              email: "",
              message: "",
            });

            recaptchaRef.current.reset();
            setErrors({ captchaVerified: false });

            navigate(
              { pathname: process.env.PUBLIC_URL + "/thank-you" },
              { replace: true }
            );
            // alert(
            //   "Thanks for contact ReactBytes. Our Exectutive will reach to you soon."
            // );
          } else {
            alert("Unknown Error Occur!!");
          }
        });
      }
    });
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);

  return (
    <>
      <div className="contact-form">
        <div className="w-full xl:w-1/2 px-5">
          <TextField
            name="name"
            id="name"
            label="Your Name"
            variant="standard"
            className="w-full"
            onChange={HandleOnchange}
            values={formvalues.name}
          />
          <span className="error" style={{ color: "red" }}>
            {errors.errorField === "name" && errors.error !== ""
              ? errors.error
              : ""}
          </span>
        </div>
        <div className="w-full xl:w-1/2 px-5">
          <TextField
            name="phonenumber"
            id="phonenumber"
            label="Mobile No"
            variant="standard"
            className="w-full"
            onChange={HandleOnchange}
            values={formvalues.phonenumber}
          />
        </div>
        <div className="w-full px-5">
          <TextField
            name="email"
            id="email"
            label="Email"
            variant="standard"
            className="w-full"
            onChange={HandleOnchange}
            values={formvalues.email}
          />
          <span className="error" style={{ color: "red" }}>
            {errors.errorField === "email" && errors.error !== ""
              ? errors.error
              : ""}
          </span>
        </div>
        <div className="w-full px-5">
          <TextField
            name="massage"
            id="massage"
            label="Message"
            variant="standard"
            multiline
            rows={3}
            className="w-full"
            onChange={HandleOnchange}
            values={formvalues.massage}
          />
          <span className="error" style={{ color: "red" }}>
            {errors.errorField === "massage" && errors.error !== ""
              ? errors.error
              : ""}
          </span>
        </div>
        <p className="text-[#959595] px-5">
          <b className="text-[#000000]">Secure:</b> We'll ensure, all the
          information provided by you will not be shared with anyone and your
          details are completely safe.
        </p>
        <div className="w-full xl:w-3/5 px-5 captcha">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={Constant.GOOGLE_RECAPTCHA_SITE_KEY}
            onChange={VerifyCallback}
          />
          <span className="error mt-2" style={{ color: "red" }}>
            {errors.errorField === "captchaVerified" && errors.error !== ""
              ? "Please Select Captcha"
              : ""}
          </span>
        </div>
        <div className="w-full xl:w-2/5 px-5 flex items-center send-msg-btn">
          <button
            type="button"
            onClick={HandleSubmit}
            className="btn-black w-full justify-center !px-4 !py-4 md:!py-5"
          >
            Send Message
          </button>
        </div>
      </div>
    </>
  );
};
export default ContactForm;
