import React, { useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../include/Footer";
import "isomorphic-fetch";
import config from "../../utils/config";
import Spinner from "../../Common/Spinner";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Header from "../include/Header";
import ScrollToTop from "../Component/ScrollTotop";
import "animate.css/animate.min.css";
import * as Constant from "../../utils/constant";
import MetaTags from "../Component/MetaTags ";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const GET_DATA = gql`
  query MyQuery($id: ID!) {
    post(id: $id, idType: SLUG) {
      id
      postId
      content
      date
      slug
      featuredImage {
        node {
          sourceUrl
        }
      }
      seo {
        title
        metaDesc
        metaKeywords
      }
    }
  }
`;
const BlogDetail = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);
  const Param = useParams();

  const { loading, error, data } = useQuery(GET_DATA, {
    variables: {
      id: Param.slug,
    },
  });

  if (error) {
    console.log("error", error);
  }

  return (
    <>
      {loading === true ? (
        <Spinner />
      ) : (
        <div>
          <div className="smallBanner blog-detail-screen bg-[#002A42]">
            <img
              src="https://d12ydcmiv69ory.cloudfront.net/reactbytes/blog-bg.jpg"
              alt="Banner"
              className="smallBannerBg"
            />
            <Header />
            <MetaTags
              title={data.post.seo.title}
              description={data.post.seo.metaDesc}
              keywords={data.post.seo.metaKeywords}
              ogImage={data.post.featuredImage.node.sourceUrl}
              ogURL={Constant.WEBSITE_URL + "/blog/" + data.post.slug}
              author={Constant.WEBSITE_AUTHOR_URL}
              facebookURL={Constant.FACEBOOK_URL}
              twitterSite={Constant.TWITTER_PAGE_CREATOR}
              twitterCreator={Constant.TWITTER_PAGE_CREATOR}
            ></MetaTags>
            <div className="container mx-auto px-4">
              <div
                className="section-title blog-detail-title text-center !text-white wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                {data.post.seo.title}
              </div>
              <ul className="custom-breadcrumb">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li className="active">
                  <Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="py-[40px] md:py-[60px] lg:py-[80px] blog-detail-new">
            <div className="single-detail-post-new">
              <LazyLoadImage
                delayTime={200}
                effect="w-full align-middle"
                src={data.post.featuredImage.node.sourceUrl}
                alt="Blog Hero"
                title="Image"
                className="w-full"
              />
              <p className="flex items-center mt-3 mb-4 text-[14px] gap-3">
                <img
                  className="lazyload"
                  src={config.LIVE_IMAGE_BASE_URL + "date.png"}
                  alt="Date"
                />
                <b>{moment(data.post.date).format("DD MMMM, YYYY")} </b>
              </p>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: data.post.content }}
              ></div>
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}
    </>
  );
};

export default BlogDetail;
