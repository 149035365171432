import React, { useEffect, useLayoutEffect, useState } from "react";
import Header from "./include/Header";
import Footer from "./include/Footer";
import ScrollToTop from "./Component/ScrollTotop";
import "animate.css/animate.min.css";
import config from "../utils/config";
import * as Constant from "../utils/constant";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MetaTags from "./Component/MetaTags ";

const testSlider1 =
  "https://images.reactbytes.com/react-bytes/testimonial-slider1.jpg";
const testSlider2 =
  "https://images.reactbytes.com/react-bytes/testimonial-slider2.jpg";
const testSlider3 =
  "https://images.reactbytes.com/react-bytes/testimonial-slider3.jpg";
const testSlider4 =
  "https://images.reactbytes.com/react-bytes/testimonial-slider4.jpg";
const videoPlay =
  "https://images.reactbytes.com/react-bytes/testimonial-video-play-icon.svg";
const testimonialsBannerBg =
  "https://images.reactbytes.com/react-bytes/testimonials-banner-bg.png";
const testimonialSisiChen =
  "https://images.reactbytes.com/react-bytes/testimonial-rahman-khan-sisi-chen.webp";
const testimonialAlyssaFranks =
  "https://images.reactbytes.com/react-bytes/alyssa-franks.webp";
const testimonialMichaelBucci =
  "https://images.reactbytes.com/react-bytes/testimonial-michael-bucci.jpg";
const testimonialAvtar =
  "https://images.reactbytes.com/react-bytes/avtar-img.svg";
const testimonialEmekaOgbonnaya =
  "https://images.reactbytes.com/react-bytes/emeka-ogbonnaya.jpg";
const testimonialAdamWard =
  "https://images.reactbytes.com/react-bytes/adam-ward.jpg";
const testimonialViggneshKandasamy =
  "https://images.reactbytes.com/react-bytes/viggnesh-kandasamy.jpg";
const testimonialRuchindraGunasekara =
  "https://images.reactbytes.com/react-bytes/ruchindra-gunasekara.jpg";
const joshuaStern =
  "https://images.reactbytes.com/react-bytes/joshua-stern.jpg";
const silvioLeoni =
  "https://images.reactbytes.com/react-bytes/silvio-leoni.png";
const michaelBucci =
  "https://images.reactbytes.com/react-bytes/michael-bucci.png";
const max = "https://images.reactbytes.com/react-bytes/client5.jpg";
const gKim = "https://images.reactbytes.com/react-bytes/client9.jpg";
const davidStockelberg =
  "https://images.reactbytes.com/react-bytes/david-stockelberg.png";
const josephRenzi = "https://images.reactbytes.com/react-bytes/client4.jpg";
const marcJordan =
  "https://images.reactbytes.com/react-bytes/tapgange_logo.png";
const emekaOgbonnaya =
  "https://images.reactbytes.com/react-bytes/buddiesout_logo.png";
const johnEdwards = "https://images.reactbytes.com/react-bytes/ideals_logo.png";
const yilmazTurker =
  "https://images.reactbytes.com/react-bytes/mail-cell_logo.png";
const danielReidler =
  "https://images.reactbytes.com/react-bytes/Wemeal_logo.png";
const antonTomassen =
  "https://images.reactbytes.com/react-bytes/naplan_logo.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96%",
  maxWidth: "800px",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const TestimonialsPage = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);
  var testimonialSliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [open, setOpen] = useState(false);
  const [testiLink, setTestiLink] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const testimonialSlider = [
    {
      clientPic: testSlider1,
      videoPlayIcon: videoPlay,
      clientName: "Sisi Chen",
      clientVideo: "https://www.youtube.com/embed/jF-mqf1UJOw",
    },
    {
      clientPic: testSlider2,
      videoPlayIcon: videoPlay,
      clientName: "Alyssa Franks",
      clientVideo: "https://www.youtube.com/embed/D0lR35w2E7M",
    },
    {
      clientPic: testSlider3,
      videoPlayIcon: videoPlay,
      clientName: "Joshua Stern",
      clientVideo: "https://www.youtube.com/embed/_EwwErZdIYE",
    },
    {
      clientPic: testSlider4,
      videoPlayIcon: videoPlay,
      clientName: "Silvio Leoni",
      clientVideo: "https://www.youtube.com/embed/de4c3dJMG-8",
    },
    {
      clientPic: testimonialMichaelBucci,
      videoPlayIcon: videoPlay,
      clientName: "Michael Bucci",
      clientVideo: "https://www.youtube.com/embed/EOiouczKY5s",
    },
  ];

  const allClientList = [
    {
      dataDelay: "0.3s",
      clientPic: testimonialAlyssaFranks,
      clientName: "Alyssa Franks",
      clientDesig: "Co-Founder of MFA App",
      clientDisc:
        "I am so glad, and so grateful that we partnered with TriState Technology. TriState has been with us from the beginning, looking at our very basic sketches and bringing all of that into a reality. The things I've loved best about working with TriState is that the communication has been consistent, reliable and fast.",
    },
    {
      dataDelay: "0.3s",
      clientPic: joshuaStern,
      clientName: "Joshua Stern",
      clientDesig: "Ceo & Co-founder Of Winendine",
      clientDisc:
        "I've been working with Tristate now for almost two years. It's been a pleasure. They've been fantastic. Their developers are strong. Project management is great, they're reliable. If anything comes up, it gets taken care of right away and I can't recommend them enough.",
    },
    {
      dataDelay: "0.4s",
      clientPic: testimonialSisiChen,
      clientName: "Sisi Chen",
      clientDesig: "Ceo & Co-Founder",
      clientDisc:
        "I am Sisi from Netherland and we are very happy that we found TriState Technology LLP. They have developed our Android App, Flurry which is now online in Google Play Store. Our contact person Jay was professional, very flexible, he was also very easy to work. The development team did a very good job by completing the app, applying our questions, finding solutions that we have faced with the previous team.",
    },
    {
      dataDelay: "0.4s",
      clientPic: silvioLeoni,
      clientName: "Silvio Leoni",
      clientDesig: "CO-FOUNDER",
      clientDisc:
        "We enjoyed working with TriState, they are very professional, respond fast and are flexible to additional querries. We will continue to work together in future projects. Thank you for the good work!”",
    },
    {
      dataDelay: "0.5s",
      clientPic: michaelBucci,
      clientName: "Michael Bucci",
      clientDesig: "LUXO SERVICES",
      clientDisc:
        "I was super happy with the finished application. Everything turned out the way we liked it. They always stay up to date with the deadlines. Overall my experience with them was fantastic. I would 100% work with then again.",
    },
    {
      dataDelay: "0.5s",
      clientPic: testimonialAvtar,
      clientName: "Eve Hughes",
      clientDesig: "CO-FOUNDER",
      clientDisc:
        "I am an extremely happy client of Tristate Technology. I've been working with them since about 2012 and the team has just been especially helpful in developing all sorts of applications. The best things about TriState is that they always clarify and make sure their requirements are straightforward and understandable. The team has been especially great on deadlines and making sure that things are developed in the time frame that I need them developed. Everything is thorough and they ask a lot of goods questions and it helps me work better with my end clients. Thank you to the team at TriState for everything you do. Thanks.",
    },
    {
      dataDelay: "0.6s",
      clientPic: max,
      clientName: "Max",
      clientDesig: "",
      clientDisc:
        "Hemant and the entire team at TriState are great to deal with. They are incredibly efficient, deliver high-end results and were transparent and honest throughout the entire process. For anyone looking for digital services, I would strongly recommend them.",
    },
    {
      dataDelay: "0.6s",
      clientPic: gKim,
      clientName: "DR. G Kim",
      clientDesig: "KIM ORTHODONTICS LLC",
      clientDisc:
        "The team @ TriState Technology are very honest, helpful, and knowledgeable. They were familiar with the latest developer rules from Apple and Google and guided me to setup all the necessary accounts I needed for both the Apple Store, Google Play Store, and website hosting. They are very agreeable and communication was very open during the entire project. Thank you TriState!",
    },
    {
      dataDelay: "0.7s",
      clientPic: davidStockelberg,
      clientName: "David Stockelberg",
      clientDesig: "FOUNDER",
      clientDisc:
        "I was very happy with what was done for us. Great quality of work and communication skills",
    },
    {
      dataDelay: "0.7s",
      clientPic: josephRenzi,
      clientName: "Joseph Renzi",
      clientDesig: "SYDNEY - AUSTRALIA",
      clientDisc:
        "One of the great things about TriState is that they were always happy to do whatever they could to make sure that we were happy. They had achieved the requirements at a high quality.",
    },
    {
      dataDelay: "0.8s",
      clientPic: marcJordan,
      clientName: "Marc Jordan",
      clientDesig: "CO-FOUNDER - TAPGANG, UNITED STATES",
      clientDisc:
        "TriState is a great contractor to work with, very knowledgeable and understanding. Always my first option when looking for a developer.",
    },
    {
      dataDelay: "0.8s",
      clientPic: emekaOgbonnaya,
      clientName: "Emeka Ogbonnaya",
      clientDesig: "CEO - BUDDIESOUT, UNITED KINGDOM",
      clientDisc:
        "As always, very professional. High quality work for a good price. Good communication and good results. I am happy to recommend this company.",
    },
    {
      dataDelay: "0.9s",
      clientPic: johnEdwards,
      clientName: "John Edwards",
      clientDesig: "CHIEF DIGITAL OFFICER - IDEALS, UNITED STATES",
      clientDisc:
        "TriState was exceptionally pleasant to work with, and we would happily work with them again for all future, app needs.",
    },
    {
      dataDelay: "0.9s",
      clientPic: testimonialEmekaOgbonnaya,
      clientName: "Emeka Ogbonnaya",
      clientDesig: "CO-FOUNDER - ZOIQUE, UNITED KINGDOM",
      clientDisc:
        "TriState has always provided quality work and produced apps to our exact specifications. Great job all around. I would highly recommend this company for web and mobile applications development.",
    },
    {
      dataDelay: "1s",
      clientPic: yilmazTurker,
      clientName: "Yilmaz Turker",
      clientDesig: "VP - MAILCELL, TURKEY",
      clientDisc:
        "5 Star...This is my second job working with TriState. First job has been great and second job is great too. If you are look for Developer then you should work with this team. Thanks for all.",
    },
    {
      dataDelay: "1s",
      clientPic: testimonialAdamWard,
      clientName: "Adam Ward",
      clientDesig: "CEO & FOUNDER - AIRTIME REWARDS, UK",
      clientDisc:
        "TriState is a great company to work with, i can see us doing lots more projects together.",
    },
    {
      dataDelay: "1.1s",
      clientPic: danielReidler,
      clientName: "Daniel Reidler",
      clientDesig: "COO - WEMEAL, UNITED STATES",
      clientDisc:
        "Pragnesh and the TriState team are very hard working. They were very helpful getting the first version of WeMeal done.",
    },
    {
      dataDelay: "1.1s",
      clientPic: testimonialViggneshKandasamy,
      clientName: "Viggnesh Kandasamy",
      clientDesig: "CEO - KLIP, UNITED STATES",
      clientDisc:
        "It was a pleasurable experience to work with Jay and the TriState team. They have a good work ethic and have a done a phenomenal job in completing the project with high quality. I would definitely recommend them to anyone looking for mobile/web development.",
    },
    {
      dataDelay: "1.2s",
      clientPic: antonTomassen,
      clientName: "Anton Tomassen",
      clientDesig: "CEO - NAPLAN, AUSTRALIA",
      clientDisc:
        "TriState team is having a first class developers, their work and communication to the client is of the highest standard. I will work with them again.",
    },
    {
      dataDelay: "1.2s",
      clientPic: testimonialRuchindraGunasekara,
      clientName: "Ruchindra Gunasekara",
      clientDesig: "FOUNDER - ADEPT TEK, AUSTRALIA",
      clientDisc:
        "TriState is a knitted team are highly efficient. They covered all bases on this project including ones I missed. I highly recommend them.",
    },
  ];

  return (
    <>
      <div className="smallBanner bg-[#002A42]">
        <img
          src={testimonialsBannerBg}
          alt="Banner"
          className="smallBannerBg"
        />
        <Header />
        <MetaTags
          title="Client Testimonials | Hear from Our Happy Clients"
          description="Hear from our clients about their positive experiences. Authentic testimonials showcasing our dedication and results."
          keywords="Client Testimonials, Valuable Feedback, Our Happy Clients"
          ogImage={config.LIVE_IMAGE_BASE_URL + "testimonials-og-img.jpg"}
          ogURL={Constant.WEBSITE_URL + "/testimonials"}
          author={Constant.WEBSITE_AUTHOR_URL}
          facebookURL={Constant.FACEBOOK_URL}
          twitterSite={Constant.TWITTER_PAGE_CREATOR}
          twitterCreator={Constant.TWITTER_PAGE_CREATOR}
        ></MetaTags>
        <div className="container mx-auto px-4">
          <div
            className="inner-page-title text-white wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Testimonials
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Here's what our clients have to say about
            <br /> working with us
          </div>
          <Slider {...testimonialSliderSettings} className="testimonial-slider">
            {testimonialSlider.map((data, i) => (
              <div className="testimonial-slider-item" key={i}>
                <img
                  src={data.clientPic}
                  alt=""
                  className="testim-slider-thumb"
                />
                <img
                  src={data.videoPlayIcon}
                  alt=""
                  className="testi-video-play"
                  width={84}
                  height={84}
                  onClick={() => {
                    handleOpen();
                    setTestiLink(data.clientVideo);
                  }}
                />
                <h3 className="testimonial-user-name">{data.clientName}</h3>
              </div>
            ))}
          </Slider>
          <div className="testimonial-all-list">
            {allClientList.map((data, i) => (
              <div
                className="w-full md:w-1/2 md:px-[15px] lg:px-[30px] flex flex-col wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
                key={i}
              >
                <div className="clients-say-user-name">
                  <img
                    src={data.clientPic}
                    alt=""
                    className="client-user-thumb"
                  />
                  <div className="user-info">
                    <h3>{data.clientName}</h3>
                    <span>{data.clientDesig}</span>
                  </div>
                </div>
                <div className="client-testimonial-text">
                  <p>{data.clientDisc}</p>
                </div>
              </div>
            ))}
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <iframe
                title="Video"
                className="testimonial-video"
                width="100%"
                height="400"
                src={testiLink}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </Box>
          </Modal>
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default TestimonialsPage;
