import React, { useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import "animate.css/animate.min.css";

const inquireImg = "https://images.reactbytes.com/react-bytes/Inquire-img.svg";

const BusinessIdea = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);

  const businessContent = [
    {
      title: "Let's turn your business \nidea into reality",
      discription:
        "Do you want to experience the best in class quality mobile apps? Send us your inquiry andone of our executives will catch you up in no time by Call, Email or Skype.",
      picture: inquireImg,
      InquireButton: "Inquire Now",
    },
  ];

  return (
    <>
      <div className="lg:pb-[30px] xl:py-[80px]">
        <div className="container mx-auto px-4">
          {businessContent.map((data, i) => (
            <div className="business-idea" key={i}>
              <div className="business-info">
                <div className="section-title lg:whitespace-pre-line">
                  {data.title}
                </div>
                <div className="section-sub-title">{data.discription}</div>
                <Link
                  to={process.env.PUBLIC_URL + "/contact-us"}
                  className="default-btn xl:!px-6 btn-hover-white"
                >
                  {data.InquireButton}
                  <svg
                    className="ml-4"
                    width="43"
                    height="20"
                    viewBox="0 0 43 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.4062 1.49951L41.4062 9.99951L32.4062 18.4995"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M40.5938 9.99951H1.59375"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </div>
              <div className="business-picture">
                <img src={data.picture} alt="" className="w-full" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BusinessIdea;
