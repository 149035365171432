import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import StyleUtils from "../../utils/styleUtils";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../style/blog.css";

const blogArrow =
  "https://images.reactbytes.com/react-bytes/arrow-up-right.svg";

const BlogCell = (props) => {
  console.log("my_id", props.id);

  const showElipsedBox = () => {
    const shortDescs = document.getElementsByClassName("shortDesc");
    for (const desc of shortDescs) {
      StyleUtils.ellipsizeTextBox(desc);
    }
  };

  useEffect(() => {
    showElipsedBox();
    window.addEventListener("resize", showElipsedBox);
    return window.removeEventListener("resize", showElipsedBox);
  }, []);

  return (
    <Link to={process.env.PUBLIC_URL + `/blog/${props.slug}`}>
      <div className="blog-list-picture">
        <LazyLoadImage delayTime={200} src={props.image} alt={props.title} />
      </div>
      <div className="blog-card-info">
        <span className="post-date">
          {moment(props.date).format("DD MMMM, YYYY")}
        </span>
        <label>{props.title}</label>
        <div className="blog-description">{props.description}</div>
        <div className="hidden lg:flex justify-end mt-[12px] lg:mt-[22px]">
          <img src={blogArrow} alt="" className="blogArrow" />
        </div>
      </div>
    </Link>
  );
};
export { BlogCell };
