import { React, useEffect, useLayoutEffect } from "react";
import Header from "../include/Header";
import Footer from "../include/Footer";
import ScrollToTop from "../Component/ScrollTotop";
import "animate.css/animate.min.css";
import config from "../../utils/config";
import * as Constant from "../../utils/constant";
import "../../Pages/Casestudy/css/casestudy-style.css";
import "../../Pages/Casestudy/css/casestudy-media.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OurProcess from "./Component/OurProcess";
import MetaTags from "../Component/MetaTags ";

const casestudyBannerBg =
  "https://images.reactbytes.com/react-bytes/investfar-banner-bg.jpg";
const BannerImage =
  "https://images.reactbytes.com/react-bytes/investfar-banner.png";
const investfarKey1 =
  "https://images.reactbytes.com/react-bytes/investfar-registration.png";
const investfarKey2 =
  "https://images.reactbytes.com/react-bytes/investfar-property-listing.png";
const investfarKey3 =
  "https://images.reactbytes.com/react-bytes/investfar-details-screen.png";
const investfarKey4 =
  "https://images.reactbytes.com/react-bytes/investfar-consultant.png";
const investfarKey5 =
  "https://images.reactbytes.com/react-bytes/investfar-book-services.png";
const investfarKey6 =
  "https://images.reactbytes.com/react-bytes/investfar-subscription-plan.png";
const investfarService1 =
  "https://images.reactbytes.com/react-bytes/investfar-my-offer-services.png";
const investfarService2 =
  "https://images.reactbytes.com/react-bytes/investfar-manage-bookings.png";
const investfarService3 =
  "https://images.reactbytes.com/react-bytes/investfar-review-rating.png";
const technologyIcon1 =
  "https://images.reactbytes.com/react-bytes/reactjs-icon.svg";
const technologyIcon2 =
  "https://images.reactbytes.com/react-bytes/laravel-logo.svg";
const Investfar = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);
  var technologyStack = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <div className="innerPageHeader casesudyBanner">
        <img
          src={casestudyBannerBg}
          alt="Banner"
          className="casestudyBannerBg"
        />
        <Header />
        <MetaTags
          title="InvestFar App Case Study | Revolutionizing Real Estate Investments"
          description="Discover how the InvestFar app transformed real estate investing. Learn about its innovative features and the success it brought to real estate investors."
          keywords="InvestFar app, real estate investing, mobile application, case study, app success, innovative features, property investment"
          ogImage={config.LIVE_IMAGE_BASE_URL + "invest-far-work-og-img.jpg"}
          ogURL={Constant.WEBSITE_URL + "/casestudy/investfar"}
          author={Constant.WEBSITE_AUTHOR_URL}
          facebookURL={Constant.FACEBOOK_URL}
          twitterSite={Constant.TWITTER_PAGE_CREATOR}
          twitterCreator={Constant.TWITTER_PAGE_CREATOR}
        ></MetaTags>
        <div className="container mx-auto px-4">
          <div className="detailPageBanner">
            <div className="detailBannerinfo">
              <h3 className="inner-page-title">
                Complete Real Estate Solution
              </h3>
              <p>
                Find an area to invest, get consultation, acquire a property,
                find contractors, and manage/relist the property.
              </p>
            </div>
            <div className="detailBannerPic !items-end">
              <img src={BannerImage} alt="Banner" className="max-w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center md:text-left wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Back Story
          </div>
          <div
            className="section-sub-title !font-normal wow animate__animated animate__fadeInUp"
            data-wow-delay="0.4s"
          >
            There are many Real Estate based mobile and web Applications
            providing different functions/facilities. But it is difficult to
            find an app that would include ALL IN ONE functions/facilities like
            Real Estate Services(Buy/Sell), Request quotes and book appointments
            from home improvement along with valuation reports, market data,
            area analytic and Property Management Tool.
          </div>
          <div
            className="section-sub-title !font-normal wow animate__animated animate__fadeInUp"
            data-wow-delay="0.5s"
          >
            This made the client think that why not develop one?
          </div>
          <div
            className="section-sub-title !font-normal wow animate__animated animate__fadeInUp"
            data-wow-delay="0.6s"
          >
            Therefore, client decided to develop a Platform, which would provide
            the complete end-to-end solution for the Real Estate domain and that
            could be accessed from anywhere in the world just on the finger tap.
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] bg-[#E7E8F6]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Key Features
          </div>
          <div
            className="section-sub-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.4s"
          >
            The InvestFar App is a simple way to get into the Real Estate
            Investing.
          </div>
          <div className="key-feature-row">
            <div className="key-feature-pic">
              <img src={investfarKey1} alt="" />
              <div className="key-image-bg"></div>
            </div>
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Registration Screen
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                To get the complete solution related to Real Estate services,
                the user registers into the InvestFar App. To skip the lengthy
                registration, the user can register using Social Media such as
                LinkedIn and Facebook.
              </p>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.5s"
              >
                The user roles are bifurcated in the registration process by
                selecting the relevant option by the users
              </p>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Property Listing
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                It will provide the user to view all the properties listed in
                the InvestFar with some details, such as Image, Name, Address,
                Price and option to add to favourites.
              </p>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.5s"
              >
                It also gives the option to post a Property with the relevant
                details.
              </p>
            </div>
            <div className="key-feature-pic">
              <img src={investfarKey2} alt="" />
              <div className="key-image-bg"></div>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-pic">
              <img src={investfarKey3} alt="" />
              <div className="key-image-bg"></div>
            </div>
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Property Detail Screen
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                It displays all the details related to the Property, such as
                images, address, size, amenities etc. To know more about the
                property, the user can also get the Property Valuation Report.
              </p>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.5s"
              >
                It also provides the option to see the Floor Plans of the
                Property and Make an offer to the property owner.
              </p>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Consultation
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                The investor can get the best Investment Consultation through
                the app itself. They can select the consultant of their own
                choice.
              </p>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.5s"
              >
                Or become an Investor Consultant with the InvestFar to help
                other investors make the wise investment.
              </p>
            </div>
            <div className="key-feature-pic">
              <img src={investfarKey4} alt="" />
              <div className="key-image-bg"></div>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-pic">
              <img src={investfarKey5} alt="" />
              <div className="key-image-bg"></div>
            </div>
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Book Services and Make Payment
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                InvestFar offers the On-Demand services for the maintenance of
                their property. One can select the best service provider based
                on the ratings, reviews and details from the list and request
                for the quotation.
              </p>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.5s"
              >
                They can book a service by providing adding the Photos,
                scheduling (Date and Time) and providing the brief description.
              </p>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.6s"
              >
                Making Online Payment is always easy and convenient to all. And
                InvestFar aims to make the user's app experience easy.Making
                Online Payment is always easy and convenient to all. And
                InvestFar aims to make the user's app experience easy.
              </p>
            </div>
          </div>
          <div className="key-feature-row">
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Subscription Plans
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                Subscription plans provides more functional accessibility to the
                user. Bigger the subscription plans, deeper you go with the
                application features.
              </p>
            </div>
            <div className="key-feature-pic">
              <img src={investfarKey6} alt="" />
              <div className="key-image-bg"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] bg-[#FAFAFA]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Service Provider/Contractor Flow and Features
          </div>
          <div className="key-feature-row key-feature-gray">
            <div className="key-feature-pic">
              <img src={investfarService1} alt="" />
              <div className="key-image-bg"></div>
            </div>
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Offered Services
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                Being a Service Provider Company, the user is allowed to add and
                manage the Services. The Service has got the two types, that is,
                Hourly and Fixed Cost Service.
              </p>
            </div>
          </div>
          <div className="key-feature-row key-feature-gray">
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Manage Bookings
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                On getting the Service Booked Request from the Investor user,
                the Service Provider/Contractor needs to confirm the booking.
              </p>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.5s"
              >
                Also the Contractor can view the pending, completed and ongoing
                services.
              </p>
            </div>
            <div className="key-feature-pic">
              <img src={investfarService2} alt="" />
              <div className="key-image-bg"></div>
            </div>
          </div>
          <div className="key-feature-row key-feature-gray">
            <div className="key-feature-pic">
              <img src={investfarService3} alt="" />
              <div className="key-image-bg"></div>
            </div>
            <div className="key-feature-info">
              <h3
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Ratings and Reviews
              </h3>
              <p
                className="wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                The contractors get Ratings and Reviews for the services they
                provide. This is the best way to know the customer experience
                and improve the services accordingly.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Our Process
          </div>
          <OurProcess />
        </div>
      </div>
      <div className="py-[40px] md:py-[60px] lg:py-[80px] 2xl:py-[120px] bg-[#FAFAFA]">
        <div className="container mx-auto px-4">
          <div
            className="section-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Technology Stack
          </div>
          <Slider {...technologyStack} className="technology-stack-slider">
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon1} alt="" />
              </div>
              <h4>Angular 6</h4>
            </div>
            <div className="stack-item">
              <div className="stack-tech">
                <img src={technologyIcon2} alt="" />
              </div>
              <h4>Laravel</h4>
            </div>
          </Slider>
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default Investfar;
