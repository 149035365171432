import React from "react";
import config from "../../utils/config";
import { Helmet } from "react-helmet";

const MetaTags = (props) => {
  if (props.fullHtml) {
    return (
      <>
        <html lang="en" />
        <Helmet></Helmet>
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title> {validateStrng(props.title)}</title>
          <meta name="robots" content="index" />
          <meta
            name="author"
            content={validateStrng(props.author)}
            data-react-helmet="true"
          />
          <meta
            name="msvalidate.01"
            content={config.BIG_VERIFICATION_CODE}
            data-react-helmet="true"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=2"
            data-react-helmet="true"
          />
          <meta
            name="document-classification"
            content="Internet"
            data-react-helmet="true"
          />
          <meta
            name="document-type"
            content="Public"
            data-react-helmet="true"
          />
          <meta
            name="document-rating"
            content="Safe for Kids"
            data-react-helmet="true"
          />
          <meta
            name="document-distribution"
            content="Global"
            data-react-helmet="true"
          />
          <link rel="profile" href="https://gmpg.org/xfn/11" />
          <link rel="author" href={validateStrng(props.author)} />
          <link
            rel="shortcut icon"
            href={config.LIVE_IMAGE_BASE_URL + "favicon64.png"}
          />
          <link rel="canonical" content={validateStrng(props.ogURL)} />
          <meta
            name="description"
            content={validateStrng(props.description)}
            data-react-helmet="true"
          />
          <meta
            name="keywords"
            content={validateStrng(props.keywords)}
            data-react-helmet="true"
          />
          <meta property="og:locale" content="en_US" data-react-helmet="true" />
          <meta property="og:type" content="article" data-react-helmet="true" />
          <meta
            property="og:title"
            content={validateStrng(props.title)}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={validateStrng(props.description)}
            data-react-helmet="true"
          />
          <meta
            property="og:url"
            content={validateStrng(props.ogURL)}
            data-react-helmet="true"
          />
          <meta
            property="article:publisher"
            content={validateStrng(props.facebookURL)}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={validateStrng(props.ogImage)}
            data-react-helmet="true"
          />
          <meta
            property="og:image:width"
            content="1200"
            data-react-helmet="true"
          />
          <meta
            property="og:image:height"
            content="600"
            data-react-helmet="true"
          />
          <meta
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            name="twitter:title"
            content={validateStrng(props.title)}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={validateStrng(props.description)}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={validateStrng(props.ogImage)}
            data-react-helmet="true"
          />
          <meta
            name="twitter:site"
            content={validateStrng(props.twitterSite)}
            data-react-helmet="true"
          />
          <meta
            name="twitter:creator"
            content={validateStrng(props.twitterCreator)}
            data-react-helmet="true"
          />
          <html lang="en" />
        </Helmet>
      </>
    );
  }
};

const validateStrng = (value) => {
  console.log("values", value);
  return value == null ? "" : value;
};

export default MetaTags;
