import { Link } from "react-router-dom";
import React, { useEffect, useLayoutEffect } from "react";
import { BlogCell } from "../../Pages/Blog/BlogCell";
import "animate.css/animate.min.css";

import { useQuery, gql } from "@apollo/client";
import Spinner from "../../Common/Spinner";

const Insight = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);
  const GET_DATA = gql`
    query {
      posts(first: 3) {
        edges {
          node {
            id
            postId
            title
            excerpt
            date
            slug
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `;

  //  const [Data,setData]=useState([]);
  const { data, error, loading } = useQuery(GET_DATA);
  console.log("state", data, "error", error, "loading", loading);
  console.log("data........", data);

  return (
    <>
      {loading === true ? (
        <Spinner />
      ) : (
        <div className="py-[40px] md:py-[60px] lg:py-[80px]">
          <div className="container mx-auto px-4">
            <div className="text-center mb-[30px] md:mb-[50px]">
              <div
                className="section-title wow animate__animated animate__fadeInUp"
                data-wow-delay="0.3s"
              >
                Insight
              </div>
              <div
                className="section-sub-title wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                Here is what we have to say, check latest technology news &
                insight
              </div>
            </div>
            <div className="flex flex-col md:flex-row flex-wrap -mx-[8px] xl:-mx-[16px] gap-4 md:gap-0">
              {data.posts.edges.map((item, index) => (
                <div className="insight-width" key={index}>
                  <div className="insight-box">
                    <BlogCell
                      id={item.node.id}
                      key={item.node.title}
                      image={
                        item.node.featuredImage
                          ? item.node.featuredImage.node.sourceUrl
                          : ""
                      }
                      title={item.node.title}
                      description={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.node.excerpt,
                          }}
                        ></div>
                      }
                      date={item.node.date}
                      slug={item.node.slug}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-[40px] lg:mt-[50px]">
              <Link to={process.env.PUBLIC_URL + "/blog"} className="btn-black">
                See More Insight
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Insight;
