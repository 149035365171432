import React, { useEffect, useLayoutEffect } from "react";
import Header from "./include/Header";
import Footer from "./include/Footer";
import ScrollToTop from "./Component/ScrollTotop";
import ContactWithoutHeader from "./ContactWithoutHeader";
import "animate.css/animate.min.css";
import MetaTags from "./Component/MetaTags ";
import config from "../utils/config";
import * as Constant from "../utils/constant";

const contactBannerBg =
  "https://images.reactbytes.com/react-bytes/contact-banner-bg.png";
const ContactUs = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);
  return (
    <>
      <div className="smallBanner bg-[#002A42]">
        <img src={contactBannerBg} alt="Banner" className="smallBannerBg" />
        <Header />
        <MetaTags
          title="Cross PlatForm Mobile App Development Company - ReactBytes"
          description="Have a unique mobile app idea in your mind? Then, React Bytes is a perfect choice for Cross-Platform mobile application development. Let's Book Your Free 30 Min Consultation With Our Experts"
          keywords="Cross- Platform Mobile Application Development"
          ogImage={config.LIVE_IMAGE_BASE_URL + "landing-page-og-img.jpg"}
          ogURL={Constant.WEBSITE_URL + "/contact-us"}
          author={Constant.WEBSITE_AUTHOR_URL}
          facebookURL={Constant.FACEBOOK_URL}
          twitterSite={Constant.TWITTER_PAGE_CREATOR}
          twitterCreator={Constant.TWITTER_PAGE_CREATOR}
        ></MetaTags>
        <div className="container mx-auto px-4">
          <div
            className="inner-page-title text-white wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Contact Us
          </div>
        </div>
      </div>
      <div className="contact-content">
        <ContactWithoutHeader />
      </div>
      <ScrollToTop />
      <Footer />
    </>
  );
};
export default ContactUs;
