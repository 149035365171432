import { React, useEffect, useLayoutEffect, useState } from "react";
import Header from "./include/Header";
import Footer from "./include/Footer";
import { Link } from "react-router-dom";
import ScrollToTop from "./Component/ScrollTotop";
import "animate.css/animate.min.css";
import config from "../utils/config";
import * as Constant from "../utils/constant";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Menu, MenuItem } from "@mui/material";
import MetaTags from "./Component/MetaTags ";

const testimonialsBannerBg =
  "https://images.reactbytes.com/react-bytes/testimonials-banner-bg.png";
const headsUpPic =
  "https://images.reactbytes.com/react-bytes/headsup-casestudy.jpg";
const investFarPic =
  "https://images.reactbytes.com/react-bytes/invest-far-casestudy.jpg";
const levaPic = "https://images.reactbytes.com/react-bytes/leva-casestudy.jpg";
// const medeloopPic =
//   "https://images.reactbytes.com/react-bytes/medeloop-casestudy.jpg";
// const lifecellPic =
//   "https://images.reactbytes.com/react-bytes/lifecell-casestudy.jpg";
// const wollettelPic =
//   "https://images.reactbytes.com/react-bytes/wollette-casestudy.jpg";
const giftsCenterPic =
  "https://images.reactbytes.com/react-bytes/gift-center-casestudy.jpg";
const motologsPic =
  "https://images.reactbytes.com/react-bytes/motologs-casestudy.jpg";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Portfolio = () => {
  const [value, setValue] = useState(0);
  const portfolioCard = [
    {
      tag: "Healthcare",
      portfolioImage: headsUpPic,
      portfolioName: "Headsup Head",
      portfolioTitle: "Health & Fitness",
      portfolioTag: ["iPhone", "Android", "Web"],
      porfolioLink: "/casestudy/headsup",
    },
    {
      tag: "Realestate",
      portfolioImage: investFarPic,
      portfolioName: "Invest Far",
      portfolioTitle: "Complete Real Estate Solution",
      portfolioTag: ["Android", "Web"],
      porfolioLink: "/casestudy/investfar",
    },
    {
      tag: "Healthcare",
      portfolioImage: levaPic,
      portfolioName: "Leva",
      portfolioTitle: "Mobile Solution for Tracking Health and Development",
      portfolioTag: ["iPhone", "Android"],
      porfolioLink: "/casestudy/leva",
    },
    // {
    //   tag: "Healthcare",
    //   portfolioImage: medeloopPic,
    //   portfolioName: "Medeloop",
    //   portfolioTitle: "Revolutionize Health Research",
    //   portfolioTag: ["iPhone", "Android", "Web"],
    //   porfolioLink: "/casestudy/portfolio",
    // },
    // {
    //   tag: "Healthcare",
    //   portfolioImage: lifecellPic,
    //   portfolioName: "Lifecell",
    //   portfolioTitle: "Lifecell",
    //   portfolioTag: ["Web"],
    //   porfolioLink: "/casestudy/portfolio",
    // },
    // {
    //   tag: "Fintech",
    //   portfolioImage: wollettelPic,
    //   portfolioName: "Wollette",
    //   portfolioTitle: "The smart digital receipt platform",
    //   portfolioTag: ["iPhone", "Android", "Web"],
    //   porfolioLink: "/casestudy/portfolio",
    // },
    {
      tag: "E-Commerce",
      portfolioImage: giftsCenterPic,
      portfolioName: "Gifts Center",
      portfolioTitle:
        "E-Commerce App with streamlined Ordering and Delivery Services",
      portfolioTag: ["iPhone", "Android", "Web"],
      porfolioLink: "/casestudy/gift-center",
    },
    {
      tag: "E-Commerce",
      portfolioImage: motologsPic,
      portfolioName: "Motologs",
      portfolioTitle: "Digital Logbook for E-Commerce",
      portfolioTag: ["Android"],
      porfolioLink: "/casestudy/loggy",
    },
  ];
  const tabPanel = ["All", "iPhone", "Android", "Web"];
  const [porfolioData, setPorfolioData] = useState(portfolioCard);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setPorfolioData(portfolioCard);
    } else {
      const portfolioFilter = portfolioCard.filter((filterData) =>
        filterData.portfolioTag.includes(tabPanel[newValue])
      );
      setPorfolioData(portfolioFilter);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 767) {
      import("wowjs").then((WOW) => {
        const wow = new WOW.WOW();
        if (typeof window !== "undefined") {
          wow.init();
        }
      });
      return () => {};
    }
  }, []);

  return (
    <>
      <div className="smallBanner bg-[#002A42]">
        <img
          src={testimonialsBannerBg}
          alt="Banner"
          className="smallBannerBg"
        />
        <Header />
        <MetaTags
          title="Our Portfolio - Explore Our Innovative and Successful Projects"
          description="Explore our portfolio to see our innovative solutions and success stories. Discover how we turn ideas into impactful projects!"
          keywords="Our Work, Our React Portfolio,"
          ogImage={config.LIVE_IMAGE_BASE_URL + "landing-page-og-img.jpg"}
          ogURL={Constant.WEBSITE_URL + "/portfolio"}
          author={Constant.WEBSITE_AUTHOR_URL}
          facebookURL={Constant.FACEBOOK_URL}
          twitterSite={Constant.TWITTER_PAGE_CREATOR}
          twitterCreator={Constant.TWITTER_PAGE_CREATOR}
        ></MetaTags>
        <div className="container mx-auto px-4">
          <div
            className="inner-page-title text-white wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Our Work
          </div>
        </div>
      </div>
      <div className="portfolio-content">
        <div className="container mx-auto px-4">
          <div className="portfolio-tabs relative">
            <div className="portfolio-filter hidden">
              <button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="pFilter-btn"
              >
                Industry
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.648809 0.769658C0.84722 0.571247 1.16891 0.571247 1.36732 0.769658L5.75 5.15234L10.1327 0.769658C10.3311 0.571247 10.6528 0.571247 10.8512 0.769658C11.0496 0.96807 11.0496 1.28976 10.8512 1.48817L6.10926 6.23011C5.91084 6.42852 5.58916 6.42852 5.39074 6.23011L0.648809 1.48817C0.450397 1.28976 0.450397 0.96807 0.648809 0.769658Z"
                    fill="#2C363E"
                    fillOpacity="0.6"
                  />
                </svg>
              </button>
              <div className="portfolio-filter-dropdown">
                <Menu
                  variant="menu"
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={handleClose}>Logout</MenuItem>
                </Menu>
              </div>
            </div>
            <Tabs
              className="portfolio-tab"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="All" {...a11yProps(0)} />
              <Tab label="iPhone" {...a11yProps(1)} />
              <Tab label="Android" {...a11yProps(2)} />
              <Tab label="Web" {...a11yProps(3)} />
            </Tabs>
            <div className="portfolio-tab-content">
              {tabPanel.map((data, i) => (
                <CustomTabPanel value={value} index={i} key={i}>
                  <div className="flex flex-wrap -mx-3 lg:-mx-4">
                    {porfolioData.map((data, i) => (
                      <div
                        className="w-full md:w-1/2 lg:w-1/3 px-3 lg:px-4 mt-[30px] lg:mt-[40px] 2xl:mt-[60px]"
                        key={i}
                      >
                        <div className="our-work-card relative">
                          <Link to={process.env.PUBLIC_URL + data.porfolioLink}>
                            <div className="work-tag">{data.tag}</div>
                            <div className="work-thumb">
                              <img
                                src={data.portfolioImage}
                                alt=""
                                className="max-w-full"
                              />
                            </div>
                            <div className="work-info">
                              <span>{data.portfolioName}</span>
                              <h3>{data.portfolioTitle}</h3>
                              <ul>
                                {data.portfolioTag.map((data, i) => (
                                  <li key={i}>{data}</li>
                                ))}
                              </ul>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </CustomTabPanel>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default Portfolio;
